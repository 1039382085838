function cutHex(hex: string) {
  return hex.charAt(0) === '#' ? hex.substring(1, 7) : hex;
}

export default function convertHexToRGB(hex: string): [number, number, number] {
  return [
    parseInt(cutHex(hex).substring(0, 2), 16),
    parseInt(cutHex(hex).substring(2, 4), 16),
    parseInt(cutHex(hex).substring(4, 6), 16),
  ];
}
