import {
  type DocumentChunk,
  type DocumentChunkManifest,
  type DocumentChunkManifestEntry,
  type DocumentChunkMap,
  type DocumentChunkMapByFilename,
  DOCUMENT_CHUNK_TYPES_TEXT,
} from '../types/chunkedDocuments';
import { notEmpty } from '../typeValidators';
// eslint-disable-next-line import/no-cycle
import delay from '../utils/delay';
// eslint-disable-next-line import/no-cycle
import exceptionHandler from '../utils/exceptionHandler.platform';
import makeLogger from '../utils/makeLogger';
import type { CacheInstance } from './Cache';
import { Cache } from './cache.platform';
// eslint-disable-next-line import/no-cycle
import stores from './stores';

// same as MAX_DOCUMENT_CHUNKS_COUNT_PER_PAGE in reader/constants.py
const MAX_DOCUMENT_CHUNKS_COUNT_PER_PAGE = 120;
const MIN_TIME_BETWEEN_LOADS_FROM_SERVER = 3000; // millis

let lastLoadedFromServerAt: number | undefined;
let isLoadingFromServer = false;
let queuedManifestsCache: CacheInstance | null = null;

const logger = makeLogger(__filename, { shouldLog: false });

function getManifestQueue(): CacheInstance {
  if (!queuedManifestsCache) {
    queuedManifestsCache = Cache.createInstance({
      name: 'queuedManifestsToLoad',
    });
  }
  return queuedManifestsCache;
}

export function loadDocumentChunksInBackground() {
  if (isLoadingFromServer) {
    logger.warn('Already loading chunks in background, bailing.');
    return;
  }
  logger.debug('Starting process to load chunks from server');
  isLoadingFromServer = true;
  loadChunkBatchFromServer();
}

export async function clearManifestsQueuedForLoad() {
  logger.debug('Clearing manifests queued for load');
  await getManifestQueue().clear();
}

export async function queueLoadForChunksFromManifest(docId: string, manifest: DocumentChunkManifest) {
  if (!await getManifestQueue().getItem(docId)) {
    logger.debug('Queueing manifest', {
      docId,
      chunkCount: Object.keys(manifest).length,
    });
    await getManifestQueue().setItem(docId, manifest);
  } else {
    logger.debug('Manifest already queued for load', {
      docId,
      chunkCount: Object.keys(manifest).length,
    });
  }
}

/**
 * We don't want to overload the client and degrade performance, so we throttle the chunk requests.
 * This will combine and request chunks from multiple documents at the same time.
 */
async function loadChunkBatchFromServer() {
  const now = performance.now();
  const docIdsInQueue = await getManifestQueue().keys();
  // sort doc IDs in queue from most recently created to least recently created.
  // this means doc manifests will not be loaded in the order they are queued, which makes this process technically
  // not FIFO. but queueing by doc ID is a much simpler and safer implementation than writing and reading an array.
  docIdsInQueue.sort().reverse();
  logger.debug('Periodically loading chunk batch from server...', {
    lastLoadedFromServerAt,
    durationSinceLastLoad: lastLoadedFromServerAt && now - lastLoadedFromServerAt,
    docIdsInQueue,
  });
  lastLoadedFromServerAt = now;

  const chunkIdBatch: string[] = [];
  const docIdsInBatch: string[] = [];
  while (docIdsInQueue.length > 0) {
    const docId = docIdsInQueue.shift(); // get the first one i.e. the most recently created doc.
    if (!docId) {
      logger.error('>0 doc IDs in queue but pop() returns nullish', { docId, docIdsInQueue });
      return;
    }
    const manifest = await getManifestQueue().getItem<DocumentChunkManifest>(docId);
    if (!manifest) {
      logger.warn('Queued manifest was already popped, skipping..', {
        docId,
      });
      continue;
    }
    // load ALL chunks from manifest
    const chunkIdsToAdd = extractChunkIdsFromManifestEntries(Object.values(manifest));
    chunkIdBatch.push(...chunkIdsToAdd);
    docIdsInBatch.push(docId);
    logger.debug('Added chunk IDs to batch for loading', {
      docId,
      chunkIdsToAdd,
      chunkIdBatch,
    });
    if (chunkIdBatch.length >= MAX_DOCUMENT_CHUNKS_COUNT_PER_PAGE) {
      break;
    }
  }

  if (chunkIdBatch.length > 0) {
    logger.debug('Loading chunk batch from server', {
      chunkIdBatch,
      docIdsInBatch,
    });
    try {
      await loadChunksByIdWithExponentialBackoff(chunkIdBatch);
      // only remove doc IDs from queue once we know loading their chunks was successful.
      await Promise.all(docIdsInBatch.map((docId) => getManifestQueue().removeItem(docId)));
    } catch (e) {
      logger.error('Error loading chunks from server', { e, chunkIdBatch, docIdsInBatch });
    }
  }

  setTimeout(loadChunkBatchFromServer, MIN_TIME_BETWEEN_LOADS_FROM_SERVER);
}

/**
 * Bookstore EPUBs can take a little while to encrypt on the server. While they're still being encrypted,
 * the server returns empty chunk data, prompting the client to retry a bit later when encryption is (hopefully) done.
 *
 * This function handles retrying the chunk downloading with an exponential backoff so we don't overload the server.
 */
async function loadChunksByIdWithExponentialBackoff(chunkIds: string[]): Promise<{ [dbId: string]: DocumentChunk; }> {
  let loadedChunks = await stores.documentContentChunks.loadByIds(chunkIds);
  let chunkIdsLeftToLoad: string[] = [];

  for (let attemptNo = 0; attemptNo < 10; attemptNo++) {
    // SAFETY: Only chunks awaiting encryption will have empty data. Search "chunk with empty data not allowed".
    chunkIdsLeftToLoad = Object
      .values(loadedChunks)
      .filter((chunk) => chunk.data === '')
      .map((chunk) => chunk.id);
    if (chunkIdsLeftToLoad.length === 0) {
      return loadedChunks;
    }

    // At most, we wait 127.8 seconds. This should be long enough for encryption to finish.
    // For the 336MB EPUB "Wind and Truth", on 2025-03-09, (not yet optimized) encryption took 67.5 seconds.
    // Most encryptions will take < 3 seconds.
    const delayDuration = Math.round(3000 * 1.3 ** attemptNo);
    logger.debug('Chunks not yet encrypted, retrying after delay..', {
      chunkIdsLeftToLoad,
      delayDuration,
      attemptNo,
    });
    await delay(delayDuration);
    // on 2nd+ load, bypass local cache so we force load of (hopefully encrypted) chunks from server.
    const reloadedChunks = await stores.documentContentChunks.loadByIds(chunkIdsLeftToLoad, true);
    loadedChunks = {
      ...loadedChunks,
      ...reloadedChunks,
    };
  }

  exceptionHandler.captureException('Timed out loading chunks by ID', {
    extra: {
      chunkIds,
      loadedChunks: Object.keys(loadedChunks),
      chunkIdsLeftToLoad,
    },
  });
  return loadedChunks;
}

function extractChunkIdsFromManifestEntries(entries: DocumentChunkManifestEntry[]): string[] {
  // Normally, items from a Store endpoint are loaded using last updated timestamps, in descending order of IDs.
  // We don't use that method for chunks, but just for a consistent order of IDs on the client we sort the same way.
  const manifestChunkIds = entries
    .sort((a, b) => Number(b.id) - Number(a.id))
    .map((e) => e.id);
  if (!manifestChunkIds.every(notEmpty)) {
    exceptionHandler.captureException(
      'Manifest entries to load contains some empty or nullish IDs',
      {
        extra: {
          manifestChunkIds,
          entries,
        },
      },
    );
    return [];
  }
  if (manifestChunkIds.length === 0) {
    exceptionHandler.captureException(
      'No manifest entries to load',
      {
        extra: {
          manifestChunkIds,
          entries,
        },
      },
    );
    return [];
  }
  return manifestChunkIds;
}

function transformToChunkMapByInternalId(chunkMapByDbId: { [dbId: string]: DocumentChunk; }): DocumentChunkMap {
  return Object.fromEntries(
    Object.values(chunkMapByDbId).map((chunk) => [chunk.internal_id, chunk]),
  );
}

async function loadChunkMapFromManifestEntries(manifestEntriesToLoad: DocumentChunkManifestEntry[]): Promise<DocumentChunkMap> {
  const manifestChunkIds = extractChunkIdsFromManifestEntries(manifestEntriesToLoad);
  logger.debug('Loading chunks from manifest', {
    manifestChunkIds,
    manifestEntriesToLoad,
    firstChunkId: manifestChunkIds[0],
    lastChunkId: manifestChunkIds[manifestChunkIds.length - 1],
  });

  const chunkMapByDbId = await loadChunksByIdWithExponentialBackoff(manifestChunkIds);
  const chunkMap = transformToChunkMapByInternalId(chunkMapByDbId);
  logger.debug('Loaded chunk map', { internalIds: Object.keys(chunkMap) });
  return chunkMap;
}

async function loadChunksByInternalIdFromManifest(
  manifest: DocumentChunkManifest,
  internalIdsToLoad: string[],
): Promise<DocumentChunkMap> {
  const manifestEntriesToLoad = internalIdsToLoad
    .map((internalId) => manifest[internalId])
    .filter(notEmpty);
  const missingManifestEntries = internalIdsToLoad.filter((internalId) => !manifest[internalId]);
  if (missingManifestEntries.length > 0) {
    exceptionHandler.captureException('Missing manifest entries for chunk internal IDs', {
      extra: {
        missingManifestEntries,
        allManifestKeys: Object.keys(manifest),
        internalIdsToLoad,
        manifestEntriesToLoad,
      },
    });
  }
  if (manifestEntriesToLoad.length === 0) {
    exceptionHandler.captureException('No manifest entries to load?', {
      extra: {
        missingManifestEntries,
        allManifestKeys: Object.keys(manifest),
        internalIdsToLoad,
        manifestEntriesToLoad,
      },
    });
    return {};
  }
  return loadChunkMapFromManifestEntries(manifestEntriesToLoad);
}

export function loadAllTextChunksFromManifest(manifest: DocumentChunkManifest): Promise<DocumentChunkMap> {
  const textChunkManifestEntries = Object
    .values(manifest)
    .filter((e) => DOCUMENT_CHUNK_TYPES_TEXT.has(e.type));
  return loadChunkMapFromManifestEntries(textChunkManifestEntries);
}

export async function loadChunksByFilenameFromManifest(
  manifest: DocumentChunkManifest,
  absoluteFilepaths: string[],
): Promise<DocumentChunkMapByFilename> {
  const filenameToInternalIdMap = Object.fromEntries(
    Object.entries(manifest).map(([internalId, entry]) => [entry.filename, internalId]),
  );
  const internalIdsToLoad = absoluteFilepaths
    .map((filepath) => filenameToInternalIdMap[filepath])
    .filter(notEmpty);
  if (internalIdsToLoad.length === 0) {
    return {};
  }
  const chunkMap = await loadChunksByInternalIdFromManifest(manifest, internalIdsToLoad);
  return Object.fromEntries(
    Object.values(chunkMap).map((chunk) => [chunk.filename, chunk]),
  );
}
