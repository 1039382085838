import { PAGINATION_DOCUMENT_TOP_MARGIN } from '../../../contentFrameInternals/types';
import { Theme } from '../../../css/baseStyles';

export const imageMaxHeightWhenPaginationEnabled = 250;

type PaginationStyleProps = {
  theme: Theme;
  deviceHeight: number;
};
export const getPaginationStyles = ({ theme, deviceHeight }: PaginationStyleProps) => {
  return `
  <style id="RWPaginationStyles">
      body {
        margin-top: ${PAGINATION_DOCUMENT_TOP_MARGIN}px;
        height: 100%;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      .hide-snapshot-content {
        display: none!important;
      }

      .hide-snapshot-content-from-selection {
        display: none!important;
      }

      .hide-divider {
        display: none!important;
      }

      .hide-content-snapshot {
        display: none!important;
      }

      .page-divider {
        position: absolute;
        z-index: 1998;
        width: 200%;
        height: 2px;
        -webkit-user-select: none;
        user-select: none;
        background: red;
        opacity: 0.3;
        background: ${theme.readerBackground};
        opacity: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .page-number {
        padding-top: 20px;
        color: ${theme.secondaryText};
        font-size: 14px;
        display: none;
      }

      .divider-border {
        width: 100%;
        height: 30px;
        transition: opacity 0.5s ease, box-shadow 0.5s ease, background 0.5s ease;
        left: -25%;
        position: relative;
        background-size: 2px 2px;
        background-position: -19px -19px;
        opacity: 0;
        box-shadow: 0px 0px 7px 0px ${theme.pageShadow} inset;
        background-image: radial-gradient(${theme.pageBorderBackground} 1px, transparent 0);
      }

      .divider-shadow {
        opacity: 1;
      }

      .non-splittable-divider {
        height: 80px;
        background: none;
        display: none;
        align-items: flex-end;
      }

      .non-splittable-divider .divider-border {
        opacity: 1;
        height: 30px;
        background: ${theme.readerBackground};
        background-image: none;
        box-shadow: none;
        transition: opacity 0.3s ease;
      }

      .non-splittable-divider .divider-shadow {
        display: none;
        opacity: 0;
      }

      .page-margin {
        position: fixed;
        z-index: 100;
        width: 100%;
        opacity: 1;
        background: ${theme.readerBackground};
        -webkit-user-select: none;
        user-select: none;
        display: none;
      }

      .document-content {
        padding-bottom: 0px!important;
        margin-bottom: 0px!important;
      }
      .document-content.mandatory-styles.specific-1.specific-2.specific-3.specific-4.specific-5.specific-6.specific-7.specific-8.specific-9.specific-10 img:not([src^="data:"]) {
        break-inside: avoid-column;
        max-height: ${imageMaxHeightWhenPaginationEnabled}px !important;
        min-height: ${imageMaxHeightWhenPaginationEnabled}px !important;
        object-fit: scale-down;
      }
      .document-content.mandatory-styles.specific-1.specific-2.specific-3.specific-4.specific-5.specific-6.specific-7.specific-8.specific-9.specific-10 img[src^="data:"] {
        max-height: ${deviceHeight * 0.5}px !important;
        object-fit: scale-down;
      }
      .document-content.mandatory-styles.specific-1.specific-2.specific-3.specific-4.specific-5.specific-6.specific-7.specific-8.specific-9.specific-10 svg:has(image) {
        max-height: ${deviceHeight * 0.5}px !important;
        object-fit: scale-down;
      }
      .document-content.mandatory-styles.specific-1.specific-2.specific-3.specific-4.specific-5.specific-6.specific-7.specific-8.specific-9.specific-10 video {
        max-height: ${imageMaxHeightWhenPaginationEnabled}px !important;
        min-height: ${imageMaxHeightWhenPaginationEnabled}px;
      }

      .document-content.mandatory-styles.specific-1.specific-2.specific-3.specific-4.specific-5.specific-6.specific-7.specific-8.specific-9.specific-10 a:has(img) {
        display: inline-block;
      }
      .document-content.mandatory-styles.specific-1.specific-2.specific-3.specific-4.specific-5.specific-6.specific-7.specific-8.specific-9.specific-10 div:not(.chunk-boundary-border):not(.rw-chunk-container) {
        height: auto!important;
        min-height: auto!important;
      }

      .document-root {
      }

      .top-page-margin {
        top: 0;
      }
      .bottom-page-margin {
        bottom: 0;
      }

      .end-of-reading-container {
        position: relative;
        z-index: 999;
      }

      .show-end-of-reading-container {
        display: none;
      }

      .bottom-margin {
          height: ${deviceHeight}px;
      }

      .pagination-margin-container {
        position: absolute;
        top:0;
        min-width: 100%;
        min-height: 100%;
      }

      .absolutely-positioned-content {
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
        position: absolute;
        z-index: 1000;
        top: 0;
        padding-bottom: 0!important;
        margin-bottom: 0!important;
        opacity: 0.8;
        background: ${theme.readerBackground};
        overflow-x: hidden;
        opacity: 1;
        left: 0;
        right: 0;
        display: initial;
      }

      .absolutely-positioned-content.bottom {
        z-index: 1001;
      }

      .absolutely-positioned-content-visible {
        z-index: 1000;
      }

      .content-bottom {
        position: relative;
      }
      .opaque-document-root {
        opacity: 0;
      }

      .debug-styles .absolutely-positioned-content {
        background: beige;
      }
      .debug-styles .absolutely-positioned-content.bottom {
        background: #f98bff;
      }
      .debug-styles .page-divider {
        background: red;
      }
      .header-secondary-image-container {
        min-height: 200px;
        max-height: 200px;
      }
  </style>
  `;
};
