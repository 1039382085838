import makeLogger from '../utils/makeLogger';
import getClosestHTMLElement from './utils/getClosestHTMLElement';
import getNextElementWithinContainer from './utils/getNextNodeWithinContainer';
import getRangyClassApplier from './utils/getRangyClassApplier';
import isFocusableElement from './utils/isFocusableElement';
import { deserializeCanonicalPosition } from './utils/locationSerialization/chunked';

class SerializedPositionError extends Error {
  name = 'SerializedPositionError';
}

const logger = makeLogger('serializedPosition');

export function getElementFromSerializedPosition(
  serializedPosition: string | undefined,
  documentTextContent: HTMLElement | undefined,
  allowNonFocusableElements = false,
): HTMLElement | undefined {
  if (!documentTextContent || !serializedPosition) {
    throw new SerializedPositionError('No document text content or serialized position');
  }

  try {
    const position = deserializeCanonicalPosition({
      classApplier: getRangyClassApplier(),
      rootNode: documentTextContent,
      serialized: serializedPosition,
    });

    const closestElement = getClosestHTMLElement(position.node);
    if (!closestElement) {
      throw new SerializedPositionError('No closest element');
    }

    if (allowNonFocusableElements) {
      return closestElement;
    }

    return isFocusableElement(closestElement)
      ? closestElement
      : (getNextElementWithinContainer({
        container: documentTextContent,
        direction: 'next',
        element: closestElement,
        matcher: isFocusableElement,
      }) as HTMLElement);
  } catch (e) {
    logger.error('Error getting element from serialized position', { cause: e });
  }
}
