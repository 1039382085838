import { LogMethodName, LogMethodNameToImpl } from '../../types/logging';
import { type LogExtra, type LoggerOptions, ReadwiseLogger } from '../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import { portalGate } from '../portalGates/contentFrame/from/reactNativeWebview';
import type { ContentFrameEventMessageArgumentMap } from '../types/events';


export function executeLog({ prefix, methodName, message, extra }: ContentFrameEventMessageArgumentMap['log']) {
  const logImpl = LogMethodNameToImpl[methodName];
  if (extra) {
    logImpl(`${prefix} ${message}`, extra);
  } else {
    logImpl(`${prefix} ${message}`);
  }
}

export class ReadwiseWebviewLogger extends ReadwiseLogger {
  protected _log(methodName: LogMethodName, message: string, extra?: LogExtra) {
    if (!this._shouldLog(methodName, message, extra)) {
      return;
    }
    const logEventArgs = { prefix: this.prefix, methodName, message, extra };
    executeLog(logEventArgs);
    portalGate.emit('log', logEventArgs);
  }
}

export function makeWebviewLogger(filenameOrName: string, options: LoggerOptions = {}) {
  return new ReadwiseWebviewLogger(filenameOrName, options);
}
