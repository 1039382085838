// eslint-disable-next-line import/no-cycle
import sum from 'lodash/sum';

import { AnyDocument, LenientReadingPosition } from '../../../types';
import makeLogger from '../../../utils/makeLogger';
// eslint-disable-next-line import/no-cycle
import { globalState, isStaffProfile, updateState } from '../../models';
import background from '../../portalGates/toBackground';

const logger = makeLogger(__filename, { shouldLog: false });

export const addExperiencePoints = async (
  newPosition: LenientReadingPosition,
  doc: AnyDocument,
  correlationId?: string,
): Promise<void> => {
  const docId = doc.id;
  const previousPosition = doc.readingPosition;

  const isChunked = doc.source_specific_data?.epub?.is_chunked ?? false;

  if (!docId) {
    return;
  }

  // Get current state
  const state = globalState.getState();

  // Check if user is eligible for XP tracking (bookwise feature check or staff)
  if (state.client.bookwise?.areWipFeaturesEnabled === undefined && !isStaffProfile(state)) {
    logger.info('User not eligible for XP tracking (not staff and bookwise features not enabled)');
    return;
  }

  // Check if XP tracking is disabled in developer settings
  const isXpTrackingDisabled = state.client.mobileDeveloperSettings.disableXpTracking;
  if (isXpTrackingDisabled) {
    logger.info('XP tracking is disabled in developer settings, not adding experience points');
    return;
  }

  let totalWordsInDoc = 0;

  if (!isChunked) {
    // Doc is unchunked, so we simply return the word count
    totalWordsInDoc = doc.word_count ?? 0;
  } else {
    const transientDoc = state.transientDocumentsData[docId];

    const spine = transientDoc?.spine;
    const manifest = transientDoc?.manifest;
    if (!spine || !manifest) {
      return;
    }
    const chunkMap = await background.loadAllTextChunksFromManifest(manifest);

    const chunksInSpine = Object.values(spine).map((chunkId) => chunkMap[chunkId]?.word_count ?? 0);

    totalWordsInDoc = sum(chunksInSpine);
  }

  const progressDelta = (newPosition.scrollDepth ?? 0) - (previousPosition?.scrollDepth ?? 0);
  let wordsRead = totalWordsInDoc * progressDelta;

  if (wordsRead <= 0) {
    return;
  }
  if (wordsRead > 1500) {
    // Set a cap of 1500 words read in case there was some bug in the progress calculation or a user jumped far using the TOC
    wordsRead = 1500;
  }

  logger.info(`adding ${wordsRead} of experience`);

  await updateState(
    (state) => {
      if (!state.persistent.experience || state.persistent.experience < 0) {
        state.persistent.experience = 0;
      }
      state.persistent.experience += wordsRead;
      state.persistent.experience = Math.round(state.persistent.experience);
    },
    { userInteraction: 'null', eventName: 'add-experience-points', isUndoable: false, correlationId },
  );
};
