import { isChunkContainer } from '../typeValidators';
import { ChunkContainerElement } from './types/chunkedDocuments';
import { populateFocusableElements } from './utils/findAllFocusableElements';
import { findCenteredElementInViewport } from './utils/findCenteredElementInViewport';
import { findChunkContainerForNode } from './utils/findChunkContainerForNode';

function getCurrentCenteredElement(documentTextContent: HTMLElement) {
  const highlightableElements: HTMLElement[] = [];

  // This function mutates the passed in array instead of returning a new one.
  // Since it is used in different places, we keep the same interface, but create an empty array to be modified.
  populateFocusableElements(documentTextContent, highlightableElements);

  const centeredElement = findCenteredElementInViewport(
      highlightableElements,
      window,
    ) as HTMLElement | null;

  return centeredElement;
}

export function getChunkContainerByIndex(index: number): ChunkContainerElement | null {
  const chunk = document.querySelector(`[data-chunk-index="${index}"]`);
  if (!chunk) {
    return null;
  }
  return chunk as ChunkContainerElement;
}

export function getChunkContainerByChunkId(chunkId: string): ChunkContainerElement | null {
  const chunk = document.querySelector(`[data-chunk-id="${chunkId}"]`);
  if (!chunk) {
    return null;
  }
  return chunk as ChunkContainerElement;
}

/**
 * Returns the index of the chunk that contains the currently centered element.
 */
export function getCurrentChunkIndex(documentTextContent: HTMLElement): number | null {
  const centeredElement = getCurrentCenteredElement(documentTextContent);
  if (!centeredElement) {
    return null;
  }

  const chunkContainer = findChunkContainerForNode(centeredElement, documentTextContent);

  if (!chunkContainer || !isChunkContainer(chunkContainer)) {
    return null;
  }
  const newIndex = parseInt(chunkContainer.dataset.chunkIndex, 10);

  return newIndex === -1 ? null : newIndex;
}
