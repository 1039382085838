import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { RxDocument, RxQuery } from 'rxdb';
import type { DeepReadonly } from 'rxdb/dist/types/types';

import type { ConvertRxDocument } from '../../../types';
import type {
  DatabaseCollectionNames,
  DatabaseCollectionNamesToDocType,
  DatabaseHookResultArray,
  DatabaseHookResultObject,
} from '../../../types/database';
// eslint-disable-next-line import/no-cycle
import exceptionHandler from '../../../utils/exceptionHandler.platform';
import makeLogger from '../../../utils/makeLogger';
import useLiveValueRef from '../../utils/useLiveValueRef';
import useStatePlusLiveValueRef from '../../utils/useStatePlusLiveValueRef';
import convertQueryResultToArray from './convertQueryResultToArray';

const logger = makeLogger(__filename);

function useResult<Data>(
  input: DatabaseHookResultObject<Data>,
): [
  [DatabaseHookResultObject<Data>['data'], DatabaseHookResultObject<Data>],
  Dispatch<SetStateAction<DatabaseHookResultObject<Data>>>,
  React.MutableRefObject<DatabaseHookResultObject<Data>>,
] {
  const [result, setResult, resultRef] = useStatePlusLiveValueRef(input);
  // eslint-disable-next-line @shopify/react-hooks-strict-return
  return [[result.data, result], setResult, resultRef];
}

export function useQuerySubscription<
  TCollectionName extends DatabaseCollectionNames,
  TResultData,
  TDocType extends DatabaseCollectionNamesToDocType[TCollectionName],
>({
  initialDataValue,
  isEnabled: isEnabledArgument = true,
  postProcessData,
  rxQuery,
  shouldSkipConversionFromRxDocuments,
  queryStringForChangeDetection = JSON.stringify(rxQuery?.mangoQuery),
  keysToPick,
  convertRxDocument,
}: {
  initialDataValue: TResultData;
  isEnabled?: boolean;
  postProcessData: (data: DeepReadonly<TDocType>[] | TDocType[] | number[]) => TResultData;
  queryStringForChangeDetection?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rxQuery?: RxQuery<TDocType, any>;
  shouldSkipConversionFromRxDocuments?: boolean;
  keysToPick?: (string | number | symbol)[];
  convertRxDocument?: ConvertRxDocument<TDocType>;
}) {
  // https://linear.app/readwise/issue/RW-32333/database-hooks-even-when-isenabled-false-an-entry-is-put-in-rxdbs
  const isEnabled = useMemo(() => isEnabledArgument && Boolean(rxQuery), [isEnabledArgument, rxQuery]);
  const isEnabledRef = useLiveValueRef(isEnabled);
  const [result, setResult, resultRef] = useResult<TResultData>({
    data: initialDataValue,
    isFetching: isEnabled,
    isFetchingInitialInput: isEnabled,
    isFetchingUpdatedInput: false,
    mangoQuery: rxQuery?.mangoQuery,
  });

  const initialDataValueRef = useRef(initialDataValue);
  const lastKnownMangoQueryForChangeDetection = useRef(queryStringForChangeDetection);

  // When rxQuery changes, set isFetching=isEnabled. When queryStringForChangeDetection changes, reset the value (too)
  useEffect(() => {
    // Don't bother if isEnabled has become false; there's another useEffect for that below
    if (!isEnabledRef.current) {
      return;
    }
    lastKnownMangoQueryForChangeDetection.current = queryStringForChangeDetection;
    if (!resultRef.current.isFetching) {
      setResult((prev) => {
        return {
          data: prev.data,
          isFetching: isEnabledRef.current,
          isFetchingInitialInput: false,
          isFetchingUpdatedInput: isEnabledRef.current,
          mangoQuery: rxQuery?.mangoQuery,
        };
      });
    }
  }, [
    initialDataValueRef,
    isEnabled,
    isEnabledRef,
    lastKnownMangoQueryForChangeDetection,
    queryStringForChangeDetection,
    resultRef,
    rxQuery,
    setResult,
  ]);

  // Reset when isEnabled becomes false
  useEffect(() => {
    if (isEnabled) {
      return;
    }
    setResult((prev) => {
      const next: DatabaseHookResultObject<TResultData> = {
        data: initialDataValue,
        isFetching: false,
        isFetchingInitialInput: false,
        isFetchingUpdatedInput: false,
        mangoQuery: rxQuery?.mangoQuery,
      };
      return isEqual(prev, next) ? prev : next;
    });
  }, [initialDataValue, isEnabled, rxQuery?.mangoQuery, setResult]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    // If you have come here on a performance related inquiry,
    // I can promise you the problem is most likely not RXDB parsing the query here,
    // but probably something upstream as a result of setResult being called (Like a react component re-rendering)
    const subscription = rxQuery?.$.subscribe({
      async next(queryResult) {
        if (!isEnabledRef.current) {
          return;
        }

        const data = convertQueryResultToArray<TDocType>(
          queryResult,
          rxQuery.collection,
          convertRxDocument,
        );

        const postProcessedData = postProcessData(data);

        // If this subscription is used for a partial hook upstream, do not update anything if we don't need to
        // Check the data from the previous result and check if any values for the keys we care about has changed
        const areValuesForKeysToPickEqual =
          keysToPick !== undefined &&
          isEqual(pick(resultRef.current.data, keysToPick), pick(postProcessedData, keysToPick));

        const newResult = {
          data: postProcessedData,
          isFetching: false,
          isFetchingInitialInput: false,
          isFetchingUpdatedInput: false,
          mangoQuery: rxQuery.mangoQuery,
        };

        // Check if the new result is different (minus the data param, which we just checked above)
        // If you're wondering "Why are we not just checking data itself",
        // that's because this optimization is mainly useful for partial docs (most likely something did change, but we don't care)
        const areOtherPropertiesOfResultEqual = isEqual(
          omit(newResult, ['data']),
          omit(resultRef.current, ['data']),
        );

        if (areValuesForKeysToPickEqual && areOtherPropertiesOfResultEqual) {
          return;
        }

        setResult(newResult);
      },

      error(error) {
        const mangoQuery = rxQuery.mangoQuery;
        logger.error('Error in useQuerySubscription', { error, keysToPick, mangoQuery });
        exceptionHandler.captureException(error, {
          extra: {
            keysToPick,
            mangoQuery,
          },
        });

        const newResult = {
          data: initialDataValueRef.current,
          isFetching: false,
          isFetchingInitialInput: false,
          isFetchingUpdatedInput: false,
          mangoQuery,
        };

        setResult(newResult);
      },
    });

    return () => {
      setResult(
        (prev) =>
          ({
            ...prev,
            isFetching: false,
            isFetchingInitialInput: false,
            isFetchingUpdatedInput: false,
          }) as DatabaseHookResultObject<TResultData>,
      );
      subscription?.unsubscribe();
    };
  }, [
    keysToPick,
    isEnabled,
    isEnabledRef,
    postProcessData,
    resultRef,
    queryStringForChangeDetection,
    rxQuery?.$,
    rxQuery?.collection,
    rxQuery?.mangoQuery,
    setResult,
    shouldSkipConversionFromRxDocuments,
    convertRxDocument,
  ]);

  /*
    TODO: figure out a better way to resolve this issue.

    Mitch discovered that sometimes the result is out-of-sync / doesn't make sense after the query changes.

    E.g. the `SearchPage` component has `$in: someIds` in a query. A `useEffect` errors/logs if the query is done
    (`isFetching === false`) and any expected result items are missing (each ID in `someIds` should have an associated
    result item).

    Initially `someIds` is `[]`, but once we populate it (from search results) and the query changes, then the result
    doesn't make sense. `isFetching` is `false` and there are no result items (when there definitely should be).

    This bad result is a temporary state; the hook will re-render with a good result soon after that. It doesn't happen
    every time and it varies depending on the component too.

    To reproduce the above example:
    1. Add a useEffect to the SearchPage component (web app), which logs when `isFetching` is `false`, there are IDs
      (the ones given to the query), and there are no results from the query.
    2. Open the search page on the web.
    3. Type a query which returns results.
    4. Reload until you see the new log you added.

    Mitch & I (Adam) spent a lot of time debugging this and logs showed that everything in this file made sense; it was
    internally consistent. However, the `SearchPage` component was getting a bad result, which made no sense.

    I tried changing tests, as well as using a reducer instead of `useState`, with no luck. The reducer approach
    shouldn't necessarily be written off; I was mid-way through writing it when I gave up on it, I didn't feel like it
    was worth spending time figuring out test failures caused by reducer logic errors when I wasn't confident a reducer
    would solve the issue. It's not like there would be a lot of reducer state fields.

    We're not sure if it's a logic error or React's fault but it seems to be a race condition. It's almost as if the
    query changes between the `setResult` call (caused by the first query finishing) and `result` updating, which should
    not be possible in React?

    One thing we noticed is that when it happens, the `mangoQuery` returned to `SearchPage` is out-of-sync with the
    input query (e.g. `$in` doesn't match). So the fix I came up with is to check for that `MangoQuery` mismatch
    scenario. If so, modify the result to have the latest input query and no data. This is what the other `useEffect`s
    would eventually do anyway.

    When the query changes, and it returns no data + `isFetching:true`, there is an additional duplicate render. I'm not
    sure how that's even possible when using `useMemo`. These renders are covered in tests.
  */
  return useMemo(() => {
    if (result[1].mangoQuery === rxQuery?.mangoQuery) {
      return result;
    }

    // Since we made it so omitting the `rxQuery` paramter means `isEnabled=false`, we needed to do this:
    const doesRxQueryExist = Boolean(rxQuery?.mangoQuery);

    return [
      result[0],
      {
        ...result[1],
        isFetching: doesRxQueryExist,
        isFetchingInitialInput: false,
        isFetchingUpdatedInput: doesRxQueryExist,
        mangoQuery: rxQuery?.mangoQuery,
      },
    ] as DatabaseHookResultArray<TResultData>;
  }, [result, rxQuery]);
}

export function useArrayQuerySubscription<
  TCollectionName extends DatabaseCollectionNames,
  TResultData = DatabaseCollectionNamesToDocType[TCollectionName][], // effectively TDocType[]
  TDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>({
  postProcessData: postProcessDataArgument,
  rxQuery,
  convertRxDocument,
  ...rest
}: Pick<
  Parameters<typeof useQuerySubscription>[0],
  'isEnabled' | 'queryStringForChangeDetection' | 'shouldSkipConversionFromRxDocuments'
> & {
  postProcessData?: (data: TDocType[]) => TResultData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rxQuery?: RxQuery<TDocType, RxDocument<TDocType, any>[]>;
  convertRxDocument?: ConvertRxDocument<TDocType>;
}) {
  const postProcessData = useCallback(
    (data) => {
      if (postProcessDataArgument) {
        return postProcessDataArgument(data);
      }
      return data;
    },
    [postProcessDataArgument],
  );
  return useQuerySubscription<TCollectionName, TResultData, TDocType>({
    initialDataValue: [] as TResultData,
    postProcessData,
    rxQuery,
    convertRxDocument,
    ...rest,
  });
}

export function useSingleDatabaseResultQuerySubscription<
  TCollectionName extends DatabaseCollectionNames,
  TResultData = DatabaseCollectionNamesToDocType[TCollectionName] | null, // effectively TDocType | null
  TDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>({
  postProcessData: postProcessDataArgument,
  rxQuery,
  ...rest
}: {
  postProcessData?: (data: TDocType[]) => TResultData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rxQuery?: RxQuery<TDocType, RxDocument<TDocType, any>>;
  shouldSkipConversionFromRxDocuments?: boolean;
  keysToPick?: (string | number | symbol)[];
}) {
  const postProcessData = useCallback(
    (data) => {
      if (postProcessDataArgument) {
        return postProcessDataArgument(data);
      }
      return data;
    },
    [postProcessDataArgument],
  );
  return useQuerySubscription<TCollectionName, TResultData | null, TDocType>({
    initialDataValue: null,
    postProcessData,
    rxQuery,
    ...rest,
  });
}

export function useNumberQuerySubscription<
  TCollectionName extends DatabaseCollectionNames,
  TResultData extends number | null = number,
  TDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>({
  initialDataValue = 0 as TResultData,
  rxQuery,
  ...rest
}: {
  initialDataValue?: TResultData;
  rxQuery?: RxQuery<TDocType, number>;
}) {
  return useQuerySubscription<TCollectionName, TResultData, TDocType>({
    initialDataValue,
    postProcessData: useCallback((data) => data[0] as TResultData, []),
    rxQuery,
    shouldSkipConversionFromRxDocuments: true,
    ...rest,
  });
}
