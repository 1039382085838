// eslint-disable-next-line import/no-cycle
import { PaginatedScrollingManager } from './PaginatedScrollingManager';
import { ScrollingManager } from './ScrollingManager';
import type { MobileContentFrameWindow } from './types';
import { VerticalScrollingManager } from './VerticalScrollingManager';

declare let window: MobileContentFrameWindow;

let scrollingManagerSingleton: ScrollingManager | undefined;
export function getScrollingManager(pagination = false, windowParam = window) {
  if (scrollingManagerSingleton) {
    return scrollingManagerSingleton;
  }
  if (pagination) {
    scrollingManagerSingleton = new PaginatedScrollingManager(windowParam);
  } else {
    scrollingManagerSingleton = new VerticalScrollingManager(windowParam);
  }
  // for easier debugging
  windowParam.s = scrollingManagerSingleton;
  return scrollingManagerSingleton;
}

export function destroyScrollingManager() {
  if (!scrollingManagerSingleton) {
    return;
  }
  scrollingManagerSingleton.destroy();
  scrollingManagerSingleton = undefined;
}
