import isEqual from 'lodash/isEqual';

import { updateAllSVGsOnContentLoad } from '../../mobile-library/contentFrameInternals/setupImages';
import { MobileContentFrameWindow } from '../../mobile-library/contentFrameInternals/types';
import { Category } from '../../types';
import {
  type ChunkSanitizationOptions, type WebviewChunks,
  type WebviewDocumentChunk,
  type WebviewStyleChunk,
UNCHUNKED_DOCUMENT_CHUNK_ID,
} from '../../types/chunkedDocuments';
import type { LenientWindow } from '../../types/LenientWindow';
import { isChunkContainer, isChunkedDocumentContentRoot } from '../../typeValidators';
import { DeferredPromise } from '../../utils/DeferredPromise';
import delay from '../../utils/delay';
import { isDevOrTest } from '../../utils/environment';
import exceptionHandler from '../../utils/exceptionHandler.platform';
import { rwSanitizeHtml } from '../../utils/rwSanitizeHtml';
import { getChunkContainerByChunkId } from '../chunkElement';
// eslint-disable-next-line import/no-cycle
import { portalGate as portalGateToForeground } from '../portalGates/contentFrame/from/reactNativeWebview';
import type { ChunkContainerElement } from '../types/chunkedDocuments';
import type { ChunkContainerEvent } from '../types/events';
import { extractHtmlBodyContent } from '../utils/extractHtmlBodyContent';
import getRangyClassApplier from '../utils/getRangyClassApplier';
import { getNumberOfNodesIfDocumentWasUntouched } from '../utils/locationSerialization/base';
import { convertCanonicalPositionToChunkAware } from '../utils/locationSerialization/chunked';
import { makeWebviewLogger } from '../utils/makeWebviewLogger';
import eventEmitter from './eventEmitter';

declare let window: LenientWindow & MobileContentFrameWindow;

const logger = makeWebviewLogger(__filename, { shouldLog: false });

const EPUB_ORIGINAL_STYLE_TAGS_ID = 'epub-original-style-tags';

export function triggerContentUnloadForChunk(chunkId: string) {
  portalGateToForeground.emit('chunk-exited-viewing-window', chunkId);
}

export function triggerContentLoadForChunk(chunkId: string) {
  portalGateToForeground.emit('chunk-entered-viewing-window', chunkId);
}

class ChunkContainer {
  readonly chunkId: string;
  readonly wordCount: number;
  readonly index: number;
  readonly element: ChunkContainerElement;
  readonly chunkChildNodeCount: number;

  hasContent: boolean;

  private _sanitizationOptions: ChunkSanitizationOptions;
  private _entryIntersectionObserver: IntersectionObserver | null = null;
  private _exitIntersectionObserver: IntersectionObserver | null = null;

  constructor(chunk: WebviewDocumentChunk, sanitizationOptions: ChunkSanitizationOptions) {
    this._sanitizationOptions = sanitizationOptions;
    this.chunkId = chunk.id;
    this.wordCount = chunk.word_count ?? 0;
    this.index = chunk.index;
    this.chunkChildNodeCount = chunk.html_child_node_count;
    this.element = document.createElement('div') as ChunkContainerElement;
    this.element.dataset.chunkId = this.chunkId;
    this.element.dataset.chunkIndex = this.index.toString();
    this.element.dataset.chunkChildNodeCount = this.chunkChildNodeCount.toString();
    this.element.dataset.chunkFilename = chunk.filename;
    // assign data-rw-epub-toc only for chunked ePUBs, because it adds some styling that would be wrong otherwise.
    if (sanitizationOptions.category === Category.EPUB && !this.isSingleChunkFullContentContainer) {
      this.element.dataset.rwEpubToc = chunk.filename;
    }
    this.element.classList.add('rw-chunk-container');
    this.hasContent = false;
    if (chunk.content) {
      this.hasContent = true;
      this._loadContentIntoElement(chunk.content);
    } else {
      this.hasContent = false;
      this._clearElementContent();
    }
  }

  setContent(content: string | null, sanitizationOptions: ChunkSanitizationOptions) {
    if (content && (!this.hasContent || !isEqual(sanitizationOptions, this._sanitizationOptions))) {
      this._sanitizationOptions = sanitizationOptions;
      this._loadContentIntoElement(content);
    } else if (!content && this.hasContent) {
      this._clearElementContent();
    }
  }

  destroy() {
    this._entryIntersectionObserver?.disconnect();
    this._exitIntersectionObserver?.disconnect();
    this._entryIntersectionObserver = null;
    this._exitIntersectionObserver = null;
  }

  startIntersectionObservers() {
    if (this.isSingleChunkFullContentContainer) {
      // no need to start intersection observers to this chunk if it's the only chunk with the full content.
      return;
    }
    this._ensureIntersectionObserversAreCreated();
    this._entryIntersectionObserver?.observe(this.element);
    this._exitIntersectionObserver?.observe(this.element);
  }

  stopIntersectionObservers() {
    if (this.isSingleChunkFullContentContainer) {
      // no need to stop intersection observers to this chunk if it's the only chunk with the full content.
      return;
    }
    this._ensureIntersectionObserversAreCreated();
    this._entryIntersectionObserver?.unobserve(this.element);
    this._exitIntersectionObserver?.unobserve(this.element);
  }

  get isSingleChunkFullContentContainer(): boolean {
    return this.chunkId === UNCHUNKED_DOCUMENT_CHUNK_ID;
  }

  private _ensureIntersectionObserversAreCreated() {
    if (this._entryIntersectionObserver && this._exitIntersectionObserver) {
      return;
    }
    this._entryIntersectionObserver = new IntersectionObserver(
      (entries) => {
        const shouldHaveContent = entries.some((entry) => entry.isIntersecting);
        if (!shouldHaveContent || this.hasContent) {
          return;
        }
        logger.debug('IntersectionObserver: loading content', {
          id: this.chunkId,
          index: this.index,
        });
        triggerContentLoadForChunk(this.chunkId);
      },
      {
        threshold: 0,
        rootMargin: '1000px 0px',
      },
    );
    this._exitIntersectionObserver = new IntersectionObserver(
      (entries) => {
        const shouldHaveContent = entries.some((entry) => entry.isIntersecting);
        if (shouldHaveContent || !this.hasContent) {
          return;
        }
        logger.debug('IntersectionObserver: unloading content', {
          id: this.chunkId,
          index: this.index,
        });
        triggerContentUnloadForChunk(this.chunkId);
      },
      {
        threshold: 0,
        rootMargin: '4000px 0px',
      },
    );
  }


  private _clearElementContent() {
    let unloadedChunkHeight = window.innerHeight;
    if (this.hasContent) {
      unloadedChunkHeight = this.element.offsetHeight;
      logger.debug(`clearing element content`, {
        chunkId: this.chunkId,
        chunkIndex: this.index,
        unloadedChunkHeight,
      });
    }
    this.element.innerHTML = '';
    this.element.style.height = `${unloadedChunkHeight}px`;
    requestAnimationFrame(() => {
      this.hasContent = false;
      eventEmitter.emit('chunk-content-unloaded', {
        chunkId: this.chunkId,
        container: this.element,
      });
    });
  }

  private _loadContentIntoElement(content: string) {
    let bodyContent: string;
    if (this.isSingleChunkFullContentContainer) {
      // the document is unchunked, so we don't need to extract content between the <body></body> tags
      bodyContent = content;
    } else {
      const extractedBodyContent = extractHtmlBodyContent(content);
      if (extractedBodyContent === null) {
        exceptionHandler.captureException('chunk HTML has no <body>, cannot load into chunk', {
          extra: {
            content,
            chunkId: this.chunkId,
          },
        });
        return;
      }
      bodyContent = extractedBodyContent;
    }
    // TODO: process <head> tags: scripts, styles, etc
    // TODO: support Section References e.g. <content src="chapter1.xhtml#section1"/>
    const heightBefore = this.element.offsetHeight;
    this.element.innerHTML = rwSanitizeHtml(
      bodyContent,
      this._sanitizationOptions.category,
      this._sanitizationOptions.isOriginalEmailView,
      this._sanitizationOptions.showEnhancedYouTubeTranscript,
    );

    if (this.isSingleChunkFullContentContainer) {
      // unchunked docs don't calculate child node count during parsing, so we need to calculate it here.
      // if we omit this, canonical location [de]serialization breaks. see convertCanonicalPositionToChunkAware().
      // however, to keep the code simpler and prevent inconsistencies, we don't (re)calculate child node count
      // for chunked documents here and instead continue to rely on values from the server.
      this.element.dataset.chunkChildNodeCount = this.element.childNodes.length.toString();
    } else {
      const clientComputedNodeCount = getNumberOfNodesIfDocumentWasUntouched(
        Array.from(this.element.childNodes),
        getRangyClassApplier(),
      );
      if (this.chunkChildNodeCount !== clientComputedNodeCount) {
        exceptionHandler.captureException(
          'Client computed child node count not equal to server side count',
          {
            extra: {
              clientComputedNodeCount,
              serverComputedNodeCount: this.chunkChildNodeCount,
              chunkId: this.chunkId,
              chunkIndex: this.index,
            },
          },
        );
      }
    }
    // Match the id attribute of the <body> tag, if it exists.
    const bodyId = content.match(/<body\s+(?:[^>]*?\s+)?id=["']([^"']*?)["'][^>]*>/i)?.[1];

    if (bodyId) {
      // We now set the id of the <body> tag to the id of the chunk.
      // This is used to support navigation links that include the body id as a fragment.
      this.element.id = bodyId;
    }

    logger.debug(`loaded content into element`, {
      chunkId: this.chunkId,
      chunkIndex: this.index,
      heightBefore,
      heightAfter: this.element.offsetHeight,
      contentLength: this.element.innerHTML.length,
    });
    this.element.style.height = '';
    requestAnimationFrame(() => {
      this.hasContent = true;
      eventEmitter.emit('chunk-content-loaded', {
        chunkId: this.chunkId,
        container: this.element,
      });
    });
  }
}

export const injectOriginalStyles = (styleChunks: WebviewStyleChunk[]): void => {
  const mainStyleSheet = styleChunks.map((styleChunk) => styleChunk.content).join('\n');

  const originalStylesElement = document.getElementById(EPUB_ORIGINAL_STYLE_TAGS_ID);

  // If the original styles element already exists, we can just update its content.
  // We don't want to add additional style tags for newly opened documents on web
  // where the document.head persists across document loads.
  if (originalStylesElement) {
    originalStylesElement.textContent = mainStyleSheet;
    return;
  }

  const styleTag = document.createElement('style');
  styleTag.textContent = mainStyleSheet;

  // Note: This id is important as the style tag is otherwise removed
  // by an observer script on mobile in ReaderWebview.tsx
  styleTag.id = EPUB_ORIGINAL_STYLE_TAGS_ID;
  document.head.appendChild(styleTag);
};


let chunkIdToContainerMap: { [chunkId: string]: ChunkContainer; } | null = null;
let initializationPromise: DeferredPromise<void> | null = null;
let initializedContentRoot: Element | null = null;
let areChunkIntersectionObserversDisabled = false;

export function initChunkedContent(
  contentRoot: Element,
  chunks: WebviewChunks,
  sanitizationOptions: ChunkSanitizationOptions,
  isPaginatedMode = false,
): DeferredPromise<void> {
  // idempotent, so safe to run if chunked content has already been initialized.
  // however, this shouldn't be called twice, so we print a warning.
  if (initializationPromise) {
    logger.warn('chunked content already initialized, bailing');
    return initializationPromise;
  }

  const { document: documentChunks, style: styleChunks } = chunks;

  injectOriginalStyles(styleChunks);

  initializationPromise = new DeferredPromise();
  initializedContentRoot = contentRoot;
  contentRoot.innerHTML = '';
  areChunkIntersectionObserversDisabled = isPaginatedMode;
  const containers = documentChunks.map((chunk) => new ChunkContainer(
    chunk, sanitizationOptions,
  ));
  chunkIdToContainerMap = Object.fromEntries(
    containers.map((container) => [container.chunkId, container]),
  );
  window.cc.chunkIdToContainerMap = chunkIdToContainerMap;
  for (const container of containers) {
    contentRoot.appendChild(container.element);
  }
  const endOfContent = document.createElement('div');
  endOfContent.id = 'end-of-content';
  contentRoot.appendChild(endOfContent);
  contentRoot.classList.add('rw-chunk-containers-root');
  if (isDevOrTest) {
    window.chunkIdToContainerMap = chunkIdToContainerMap;
  }
  logger.debug('initialized chunked content', { chunks: documentChunks.map((c) => [c.id, Boolean(c.content)]) });
  updateAllSVGsOnContentLoad();
  requestAnimationFrame(() => {
    if (initializationPromise) {
      initializationPromise.resolve();
    }
    eventEmitter.emit('chunked-content-initialized');
  });
  return initializationPromise;
}

export async function startChunkContainerIntersectionObservers() {
  if (areChunkIntersectionObserversDisabled) {
    return;
  }
  // intersection observers detect when a chunk is about to enter or exit the viewing window.
  // if entering, triggers a load of that chunk's content.
  // if exiting, triggers an unload of that chunk's content.
  // you need to start them so that document content is correctly loaded.
  await waitForChunkedContentToBeInitialized();
  if (!chunkIdToContainerMap) {
    exceptionHandler.captureException('chunked content initialization failed: startChunkContainerIntersectionObservers');
    return;
  }
  for (const container of Object.values(chunkIdToContainerMap)) {
    container.startIntersectionObservers();
  }
  logger.debug('started chunk container intersection observers');
}

export async function stopChunkContainerIntersectionObservers() {
  await waitForChunkedContentToBeInitialized();
  if (!chunkIdToContainerMap) {
    exceptionHandler.captureException('chunked content initialization failed: stopChunkContainerIntersectionObservers');
    return;
  }
  for (const container of Object.values(chunkIdToContainerMap)) {
    container.stopIntersectionObservers();
  }
  logger.debug('stopped chunk container intersection observers');
}

export async function updateChunkedContent(chunks: WebviewDocumentChunk[], sanitizationOptions: ChunkSanitizationOptions) {
  if (!initializationPromise) {
    exceptionHandler.captureException('Race condition: chunked content UPDATE was called before INIT');
    logger.error('Race condition: chunked content UPDATE was called before INIT');
    await waitForChunkedContentToBeInitialized();
  } else {
    await initializationPromise;
  }
  if (!chunkIdToContainerMap) {
    throw new Error('chunked content initialization failed: updateChunkedContent');
  }
  for (const chunk of chunks) {
    const container: ChunkContainer | undefined = chunkIdToContainerMap[chunk.id];
    if (!container) {
      throw new Error(`no chunk container: ${chunk.id}`);
    }
    container.setContent(chunk.content, sanitizationOptions);
  }
  updateAllSVGsOnContentLoad();
  logger.debug('updated chunked content', {
    loaded: chunks.filter((c) => c.content).map((c) => c.id),
    sanitizationOptions,
  });
}

export function destroyChunkedContent() {
  // idempotent, so it's safe to run even if chunked content hasn't been initialized.
  if (!chunkIdToContainerMap) {
    logger.warn('no chunked content to destroy, skipping');
    return;
  }
  for (const container of Object.values(chunkIdToContainerMap)) {
    container.destroy();
  }
  chunkIdToContainerMap = null;
  initializationPromise = null;
  initializedContentRoot = null;
  logger.debug('destroyed chunked content');
}

export function getChunkContainerStatus(chunkId: string): 'loaded' | 'unloaded' | 'nonexistent' {
  if (!chunkIdToContainerMap) {
    return 'nonexistent';
  }
  const container = chunkIdToContainerMap[chunkId];
  if (!container) {
    return 'nonexistent';
  }
  return container.hasContent ? 'loaded' : 'unloaded';
}

export function getChunkIndexToChunkIdMap(): { [chunkIndex: string]: string; } {
  if (!chunkIdToContainerMap) {
    throw new Error('getChunkIndexToChunkIdMap: chunked content was not initialized');
  }
  return Object.fromEntries(
    Object
      .values(chunkIdToContainerMap)
      .map((container) => [container.index.toString(), container.chunkId]),
  );
}

export function getChunkIdToContainerMap(): typeof chunkIdToContainerMap {
  if (!chunkIdToContainerMap) {
    throw new Error('getChunkIndexToChunkIdMap: chunked content was not initialized');
  }
  return chunkIdToContainerMap;
}

export function contentIsChunked() {
  if (chunkIdToContainerMap) {
    return true;
  }
  // chunked content may not be initialized yet, so we resort to detecting the CSS class in the content root.
  const contentRoot = document.getElementById('document-text-content');
  if (contentRoot) {
    return isChunkedDocumentContentRoot(contentRoot);
  }
  return false;
}

async function waitForChunkedContentToBeInitialized(): Promise<void> {
  if (initializationPromise) {
    return initializationPromise;
  }
  await eventEmitter.waitFor('chunked-content-initialized');
}

async function waitForChunkContentToLoad(chunkId: string): Promise<void> {
  if (!chunkIdToContainerMap) {
    logger.error('Content is not chunked, cannot wait for load', { chunkId });
    return;
  }
  const container = chunkIdToContainerMap[chunkId];
  if (!container) {
    logger.error('No container with chunk id, cannot wait for load', { chunkId });
    return;
  }
  if (container.hasContent) {
    return;
  }
  const promise = new DeferredPromise<void>();
  const onChunkContentLoaded = async ({ chunkId: loadedChunkId }: ChunkContainerEvent) => {
    if (loadedChunkId !== chunkId) {
      return;
    }
    eventEmitter.removeListener('chunk-content-loaded', onChunkContentLoaded);
    if (window.isSlowChunkLoadingEnabled) {
      // For developers only, this can be triggered on mobile in settings
      // This helps debug race conditions by delaying the setting of a chunk
      await delay(4000);
    }
    promise.resolve();
  };
  eventEmitter.addListener('chunk-content-loaded', onChunkContentLoaded);
  await promise;
}

async function waitForChunkContentToUnload(chunkId: string): Promise<void> {
  if (!chunkIdToContainerMap) {
    logger.error('Content is not chunked, cannot wait for unload', { chunkId });
    return;
  }
  const container = chunkIdToContainerMap[chunkId];
  if (!container) {
    logger.error('No container with chunk id, cannot wait for unload', { chunkId });
    return;
  }
  if (!container.hasContent) {
    return;
  }
  const promise = new DeferredPromise<void>();
  const onChunkContentUnloaded = ({ chunkId: unloadedChunkId }: ChunkContainerEvent) => {
    if (unloadedChunkId !== chunkId) {
      return;
    }
    eventEmitter.removeListener('chunk-content-unloaded', onChunkContentUnloaded);
    promise.resolve();
  };
  eventEmitter.addListener('chunk-content-unloaded', onChunkContentUnloaded);
  await promise;
}

export function getChunkIdFromSerializedPosition(serializedPosition: string): string | undefined {
  if (!initializedContentRoot) {
    throw new Error('chunked content initialized but no content root');
  }
  const chunkAware = convertCanonicalPositionToChunkAware(serializedPosition, initializedContentRoot);
  if (!chunkAware) {
    exceptionHandler.captureException('could not convert serialized position to chunk aware', {
      extra: {
        serializedPosition,
      },
    });
    return;
  }
  return chunkAware.chunkId;
}

export async function forceChunkContentLoadAtPosition(serializedPosition: string): Promise<string | undefined> {
  if (!contentIsChunked()) {
    return;
  }
  if (!initializedContentRoot) {
    throw new Error('chunked content initialized but no content root');
  }
  await waitForChunkedContentToBeInitialized();
  const chunkId = getChunkIdFromSerializedPosition(serializedPosition);
  if (!chunkId) {
    return;
  }
  logger.debug('forceChunkContentLoadAtPosition ', { serializedPosition, chunkId });
  return forceContentLoadForChunk(chunkId);
}

export async function forceContentLoadForChunk(chunkId: string): Promise<string> {
  const startTime = performance.now();
  const promise = waitForChunkContentToLoad(chunkId);
  triggerContentLoadForChunk(chunkId);
  await promise;
  logger.debug('manually triggered load for chunk', { chunkId, duration: performance.now() - startTime });
  return chunkId;
}

export async function forceContentUnloadForChunk(chunkId: string): Promise<string> {
  const startTime = performance.now();
  const promise = waitForChunkContentToUnload(chunkId);
  triggerContentUnloadForChunk(chunkId);
  await promise;
  logger.debug('manually triggered unload for chunk', { chunkId, duration: performance.now() - startTime });
  return chunkId;
}

export function forceContentLoadForContainer(container: ChunkContainerElement): Promise<string> {
  return forceContentLoadForChunk(container.dataset.chunkId);
}


export function forceContentUnloadForContainer(container: ChunkContainerElement): Promise<string> {
  return forceContentUnloadForChunk(container.dataset.chunkId);
}

export async function emitEventWhenChunkContentAtPositionLoads(serializedPosition: string, eventToken: string): Promise<void> {
  await waitForChunkedContentToBeInitialized();
  if (!initializedContentRoot) {
    throw new Error('chunked content initialized but no content root');
  }
  const chunkAware = convertCanonicalPositionToChunkAware(serializedPosition, initializedContentRoot);
  if (!chunkAware) {
    exceptionHandler.captureException('could not convert serialized position to chunk aware', {
      extra: {
        serializedPosition,
      },
    });
    return;
  }
  await waitForChunkContentToLoad(chunkAware.chunkId);
  logger.debug('chunked content at position loaded', {
    serializedPosition,
    chunkId: chunkAware.chunkId,
  });
  await portalGateToForeground.emit('chunk-content-at-position-loaded', eventToken);
}

// NOTE: _Always_ fires an event, even when chunk content is already initialized at time of call.
export async function emitEventWhenChunkedContentInitialized(): Promise<void> {
  logger.debug('Emitting event when chunk content initialized..');
  await waitForChunkedContentToBeInitialized();
  await portalGateToForeground.emit('chunk-content-initialized');
}

export function getChunkContainerElements(): NodeListOf<ChunkContainerElement> {
  if (!initializedContentRoot) {
    throw new Error('getChunkContainerElements called without initialized content root');
  }
  return initializedContentRoot.querySelectorAll<ChunkContainerElement>('.rw-chunk-container');
}

export async function loadSurroundingChunksAndUnloadAllOthers(container: ChunkContainerElement, optionalChunkIdsToLoad: string[] = []): Promise<void> {
  const surroundingContainers = [
    container.previousElementSibling,
    container,
    container.nextElementSibling,
  ].filter(isChunkContainer);

  const optionalChunkElementsToLoad = optionalChunkIdsToLoad.map(getChunkContainerByChunkId).filter(isChunkContainer);

  const containersToLoad = [...surroundingContainers, ...optionalChunkElementsToLoad];

  const containersToUnload = Array.from(getChunkContainerElements()).filter((container) => !containersToLoad.includes(container));
  const contentLoadPromises = containersToLoad.filter(chunkContainerHasNoContent).map(forceContentLoadForContainer);
  const contentUnloadPromises = containersToUnload.filter(chunkContainerHasContent).map(forceContentUnloadForContainer);
  await Promise.all(
    contentUnloadPromises.concat(...contentLoadPromises),
  );
}

window.cc = {
  loadSurroundingChunksAndUnloadAllOthers,
  forceContentUnloadForChunk,
  forceContentLoadForChunk,
  chunkIdToContainerMap,
  getChunkIdToContainerMap,
};

export async function getChunkContainersRoot(): Promise<Element> {
  await waitForChunkedContentToBeInitialized();
  if (!initializedContentRoot) {
    throw new Error('chunked content initialized but no content root');
  }
  return initializedContentRoot;
}

export async function getAllChunkContainerElements(): Promise<ChunkContainerElement[]> {
  const contentRoot = await getChunkContainersRoot();
  return Array.from(contentRoot.querySelectorAll<ChunkContainerElement>('.rw-chunk-container'));
}

export function chunkContainerHasContent(container: ChunkContainerElement): boolean {
  return container.childNodes.length > 0;
}

export function chunkContainerHasNoContent(container: ChunkContainerElement): boolean {
  return !chunkContainerHasContent(container);
}
