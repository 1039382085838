import convertHexToRGB from './convertHexToRGB';

export default function getRgbFromColor(color?: string): [number, number, number] | undefined {
  if (!color) {
    return;
  }

  if (color.startsWith('#')) {
    return convertHexToRGB(color);
  }

  if (color.startsWith('rgb')) {
    const matches = color.match(/rgba?\((\d+),\s?(\d+),\s?(\d+)(,\s?(\d+))?\)/i);

    if (matches) {
      const rgba = [...matches.slice(1, 4), matches[5]].map((input) =>
        input === undefined ? undefined : parseInt(input, 10));
      // Ignore if transparent
      if (!(rgba.length > 3 && rgba[3] === 0)) {
        const rgb = rgba.slice(0, 3); // Discard transparency
        if (rgb.every((item) => typeof item === 'number')) {
          return rgb as [number, number, number];
        }
      }
    }
  }
}
