// eslint-disable-next-line import/no-cycle
import { selectImageElementToHighlight } from '../mobile-library/contentFrameInternals/getImagePressingInitializer';
import {
  destroyScrollingManager,
  getScrollingManager,
} from '../mobile-library/contentFrameInternals/getScrollingManager';
import { computeHeaderImageColorData } from '../mobile-library/contentFrameInternals/imageUtils';
import { renderTags } from '../mobile-library/contentFrameInternals/initTags';
import { setYTAutoScrolling } from '../mobile-library/contentFrameInternals/initYouTubeTeleprompter';
import { PaginatedScrollingManager } from '../mobile-library/contentFrameInternals/PaginatedScrollingManager';
import { TouchEventDisabledSource } from '../mobile-library/contentFrameInternals/ScrollingManager';
import { MobileContentFrameWindow } from '../mobile-library/contentFrameInternals/types';
import { VerticalScrollingManager } from '../mobile-library/contentFrameInternals/VerticalScrollingManager';
import { ThemeCSSMap } from '../mobile-library/css/baseStyles';
import { getWebviewInnerStyle, WebviewStyleProps } from '../mobile-library/ReaderWebview/WebviewHTML/styles';
import getWebviewHorizontalMarginCss from '../mobile-library/utils/getWebviewHorizontalMarginCss';
import { LenientReadingPosition, TtsPosition } from '../types';
import type { ChunkSanitizationOptions, WebviewChunks } from '../types/chunkedDocuments';
import type { DocumentTag } from '../types/tags';
import { isInReactNativeWebView } from '../utils/environment';
import { startChunkContainerIntersectionObservers } from './contentFramePortalGateInternalMethods';

declare let window: MobileContentFrameWindow;
const ensureIsMobileWebview = () => {
  if (!isInReactNativeWebView) {
    throw new Error('Cannot call this function outside react native webview');
  }
};

export const mobileSetStyles = ({
  deviceWidth,
  deviceHeight,
  isDigestView,
  isEmailOriginalView,
  isPaginatedMode,
  isPDFDigestPreview,
  isYoutubeVideo,
  font,
  areEpubOriginalStylesEnabled,
  marginTop,
  isHighContrast,
  horizontalMargin,
  fontSize,
  lineHeight,
  shouldJustifyText,
  isRtl,
  theme,
  isTablet,
  platformOS,
}: WebviewStyleProps) => {
  ensureIsMobileWebview();
  window.theme = theme;
  const styleElement = document.getElementById('generatedStyles');
  if (!styleElement) {
    return;
  }
  styleElement.innerHTML = getWebviewInnerStyle({
    deviceWidth,
    deviceHeight,
    isDigestView,
    isEmailOriginalView,
    isPaginatedMode,
    isPDFDigestPreview,
    isYoutubeVideo,
    font,
    areEpubOriginalStylesEnabled,
    marginTop,
    isHighContrast,
    horizontalMargin,
    fontSize,
    lineHeight,
    shouldJustifyText,
    theme,
    isRtl,
    isTablet,
    platformOS,
  });

  document.body.classList.remove(...Object.values(ThemeCSSMap));
  document.body.classList.add(ThemeCSSMap[theme.type]);
  document.body.classList.remove('high-contrast');
  if (isHighContrast) {
    document.body.classList.add('high-contrast');
  }
  computeHeaderImageColorData();

};

export const mobileSetScrollingManagerType = async (isPaginatedMode: boolean) => {
  // Switch up the scrolling manager if we need to
  let currentScrollingManager = getScrollingManager(isPaginatedMode);
  if (
    isPaginatedMode && currentScrollingManager instanceof VerticalScrollingManager ||
    !isPaginatedMode && currentScrollingManager instanceof PaginatedScrollingManager
  ) {
    currentScrollingManager.disableAllTouchEvents(TouchEventDisabledSource.Init);
    const currentScrollPos = currentScrollingManager.computeSerializedPositionFromCenteredElement();
    const currentReadingPosition = currentScrollingManager.readingPosition;
    destroyScrollingManager();
    currentScrollingManager = getScrollingManager(isPaginatedMode);
    currentScrollingManager.init();
    await startChunkContainerIntersectionObservers();
    requestAnimationFrame(() => {
      currentScrollingManager.readingPosition = currentReadingPosition;
      currentScrollingManager.enableAllTouchEvents(TouchEventDisabledSource.Init);
      if (currentScrollPos) {
        currentScrollingManager.scrollToSerializedPosition(currentScrollPos.serializedPosition, currentScrollPos.absoluteTopElementOffset);
      }
      if (currentScrollingManager.documentRoot) {
        currentScrollingManager.documentRoot.classList.remove('opaque-document-root');
      }
    });
  }
};
export const mobileCreateHighlightAtTtsPosition = async (ttsPos: TtsPosition) => {
  ensureIsMobileWebview();
  getScrollingManager().createHighlightAtTtsPosition(ttsPos);
};

export const mobileScrollToTtsPosition = (chunkId: string, ttsPosition: TtsPosition, skipIndicatorUpdate: boolean) => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  return scrollingManager.scrollToTtsPosition(chunkId, ttsPosition, skipIndicatorUpdate);
};

export const mobileGetScrollDepthFromTtsPosition = async (ttsPosition: TtsPosition) => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  return scrollingManager.getScrollDepthForTtsPosition(ttsPosition);
};

export const mobileSetReadingPosition = (pos: LenientReadingPosition | null) => {
  ensureIsMobileWebview();
  getScrollingManager().setReadingPosition(pos);
};
export const mobileReturnToReadingPosition = () => {
  ensureIsMobileWebview();
  getScrollingManager().returnToReadingPosition();
};

export const mobileScrollToTop = () => {
  ensureIsMobileWebview();
  getScrollingManager().scrollToTop();
};

export const mobileToggleScrollingEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  getScrollingManager().toggleScrollingEnabled(enabled);
};

export const mobileToggleTTSAutoScrolling = (enabled: boolean) => {
  ensureIsMobileWebview();
  getScrollingManager().toggleTTSAutoScrolling(enabled);
};

export const mobilePlayTtsFromCurrentScrollPosition = () => {
  ensureIsMobileWebview();
  getScrollingManager().playTtsFromCurrentScrollPosition();
};

export const updateEndOfReadingText = (text: string) => {
  ensureIsMobileWebview();
  const endOfReadingText = document.getElementById('end-of-reading-text');
  if (!endOfReadingText) {
    return;
  }
  endOfReadingText.innerHTML = text;
};

export const mobileSelectImageElementToHighlight = async (imageId: string) => {
  ensureIsMobileWebview();
  const image = document.getElementById(imageId) as HTMLImageElement;
  if (image === null) {
    throw new Error('highlightImage - couldnt find image with provided id');
  }
  return selectImageElementToHighlight(image);
};

export const mobileChangeAutohighlightingStatus = async (isEnabled: boolean) => {
  ensureIsMobileWebview();
  window.isAutoHighlightingEnabled = isEnabled;
  const textContent = document.getElementById('document-text-content');
  if (!textContent) {
    return;
  }
  if (isEnabled) {
    textContent.classList.add('document-text-content--auto-highlighting-enabled');
  } else {
    textContent.classList.remove('document-text-content--auto-highlighting-enabled');
  }
};

export const mobileRenderTags = (tags: DocumentTag[]) => {
  ensureIsMobileWebview();
  renderTags(tags);
};

export const mobileUpdateSummary = (summary: string) => {
  ensureIsMobileWebview();

  const summaryContainer = document.getElementById('summary-text');
  if (!summaryContainer) {
    return;
  }
  summaryContainer.innerText = summary;

  const parentContainer = document.getElementById('summary-container');
  if (parentContainer) {
    if (parentContainer.style.maxHeight) {
      parentContainer.style.maxHeight = `${parentContainer.scrollHeight}px`;
    }
  }
};

export const mobileToggleYTAutoScrolling = (enabled: boolean) => {
  ensureIsMobileWebview();
  setYTAutoScrolling(enabled);
};

export const mobileTogglePaginationAnimationsEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  scrollingManager.smoothAnimationsDisabled = enabled;
};
export const mobileTogglePaginationHapticsOnScrollEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  scrollingManager.hapticsOnScrollEnabled = enabled;
};

export const mobileScrollToNextPage = () => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  if (
    !(scrollingManager instanceof PaginatedScrollingManager)
  ) {
    return;
  }
  scrollingManager.queueScrollToPageEvent({ direction: 'down', velocity: 0, disableAnimation: true });
};

export const mobileScrollToPreviousPage = () => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  if (
    !(scrollingManager instanceof PaginatedScrollingManager)
  ) {
    return;
  }
  scrollingManager.queueScrollToPageEvent({ direction: 'up', velocity: 0, disableAnimation: true });
};

const BOTTOM_BAR_PLUS_TWO_LINES_OF_TEXT_APPROX_HEIGHT = 110;

export const mobileScrollApproxOnePage = (direction: 'up' | 'down') => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  if (!(scrollingManager instanceof VerticalScrollingManager)) {
    return;
  }
  const scrollOffset = window.innerHeight - BOTTOM_BAR_PLUS_TWO_LINES_OF_TEXT_APPROX_HEIGHT;
  const positionToScroll =
    direction === 'up'
      ? scrollingManager.currentScrollValue - scrollOffset
      : scrollingManager.currentScrollValue + scrollOffset;
  scrollingManager.scrollToPosition(positionToScroll);
};

export async function mobileInitContent(chunks: WebviewChunks, isPaginatedMode: boolean, sanitizationOptions: ChunkSanitizationOptions) {
  ensureIsMobileWebview();
  window.initContent(chunks, isPaginatedMode, sanitizationOptions);
}

export function mobileOnChunkedContentUpdated() {
  ensureIsMobileWebview();
  getScrollingManager()?.onContentChanged();
}

export const setHorizontalMargin = async (margin: number, isTablet: boolean) => {
  ensureIsMobileWebview();
  const style = document.getElementById('RWMarginStyle');
  if (!style) {
    return;
  }

  style.innerHTML = getWebviewHorizontalMarginCss(margin, isTablet);
};

export const mobileRetryLoadImages = () => {
  ensureIsMobileWebview();
  if (!window.reloadImagesThatFailedToLoad) {
    return;
  }
  window.reloadImagesThatFailedToLoad();
};

export const refreshPaginationPageSnapshots = async () => {
  ensureIsMobileWebview();
  getScrollingManager().refreshPageSnapshotsForCurrentPage();
};

export const forceScroll = async () => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  scrollingManager.enableScrollEvents();
  scrollingManager.onScrollStart();
  scrollingManager.onScroll();
  scrollingManager.onScrollEnd();
};

export const mobileSetIsSlowChunkLoadingEnabled = async (enabled: boolean) => {
  window.isSlowChunkLoadingEnabled = enabled;
};

export const mobileSetIsScrollingManagerFPSLoggingEnabled = async (enabled: boolean) => {
  window.isScrollingManagerFPSLoggingEnabled = enabled;
};
