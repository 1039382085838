import getBoundingClientRect from './getBoundingClientRect';

function isInDocument(subject: Element | Range | DOMRect): boolean {
  if (subject instanceof Element) {
    return document.contains(subject);
  } else if (subject instanceof Range) {
    return document.contains(subject.commonAncestorContainer);
  } else {
    // For DOMRect, we can't determine if it's in the document directly
    // since it's just coordinates without DOM reference
    return true;
  }
}

export default async ({
  subject,
  scrollableRoot,
  canCauseReflow,
  rootPercentRange,
}: {
  subject: Element | Range | DOMRect;
  scrollableRoot: HTMLElement;
  canCauseReflow?: boolean;
  rootPercentRange?: { top: number; bottom: number; };
}): Promise<{
  isBottomInView: boolean;
  isCompletelyInView: boolean;
  isInView: boolean;
  isTopInView: boolean;
  rect: DOMRect;
  scrollableRect: DOMRect;
}> => {
  let rect: DOMRect;
  if (subject instanceof DOMRect) {
    rect = subject;
  } else if (canCauseReflow || subject instanceof Range) {
    rect = subject.getBoundingClientRect();
  } else {
    rect = await getBoundingClientRect(subject);
  }

  const scrollableRect = canCauseReflow
    ? scrollableRoot.getBoundingClientRect()
    : await getBoundingClientRect(scrollableRoot);

  let rootTop = scrollableRect.top;
  let rootBottom = scrollableRect.bottom;
  if (rootPercentRange) {
    const rootHeight = rootBottom - rootTop;
    rootTop = rootTop + rootHeight * rootPercentRange.top;
    rootBottom = rootTop + rootHeight * rootPercentRange.bottom;
  }

  const isPointInView = (point: number) => point >= rootTop && point <= rootBottom;

  const isBottomInView = isPointInView(rect.bottom);
  const isTopInView = isPointInView(rect.top);

  // For chunked documents, we can get into a race condition where the subject is no longer in the document
  // after the above asynchronous call to getBoundingClientRect.
  // This check ensures that we don't return visibility details for a subject that is no longer in the document.
  const isElementStillInDocument = isInDocument(subject);

  if (!isElementStillInDocument) {
    return {
      isBottomInView: false,
      isCompletelyInView: false,
      isInView: false,
      isTopInView: false,
      rect,
      scrollableRect,
    };
  }

  return {
    isBottomInView,
    isCompletelyInView: isBottomInView && isTopInView,
    isInView: isBottomInView || isTopInView || rect.top < rootTop && rect.bottom > rootBottom,
    isTopInView,
    rect,
    scrollableRect,
  };
};
