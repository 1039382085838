import type { DocumentWithLanguage, FirstClassDocument, TransientDocumentData, WordBoundary } from '../../types';
import { UNCHUNKED_DOCUMENT_CHUNK_ID } from '../../types/chunkedDocuments';
import type {
  PlaybackRate,
  TextToSpeechGender,
  TextToSpeechInfo,
  TextToSpeechSettings,
  TextToSpeechTrack,
  TTSLanguage,
} from '../../types/tts';
import {
  PlaybackRates,
  TextToSpeechGenderByVoice,
  TextToSpeechVoice,
  TextToSpeechVoicesByLanguage,
  TextToSpeechVoiceToApiVersion,
  TrackPlayerState,
} from '../../types/tts';
import delay from '../../utils/delay';
// eslint-disable-next-line import/no-cycle
import exceptionHandler from '../../utils/exceptionHandler.platform';
import getDocumentLanguage from '../../utils/getDocumentLanguage';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
// eslint-disable-next-line import/no-cycle
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import database from '../database';
import { CancelStateUpdate, globalState, updateState } from '../models';
import { sortDocumentSpineByChunkIds } from '../stateHooks/useChunksForDocument';
import { overrideLanguage } from '../stateUpdaters/persistentStateUpdaters/documents/overrides';
import { createToast } from '../toasts.platform';
import type { StateUpdateOptions, StateUpdateOptionsWithoutEventName } from '../types';
import {
  textToSpeechDefaultGender,
  textToSpeechDefaultLanguage,
  textToSpeechDefaultPlaybackRate,
  textToSpeechDefaultVolume,
} from '../utils/tts';

/*
  Public functions are idempotent e.g. calling `.stop()` when the track is already stopped is a no-op.
  "track player" = the underlying API/element/thing that actually plays the audio.
*/
abstract class AbstractTtsController<TTrack extends TextToSpeechTrack = TextToSpeechTrack> {
  trackPlayerCreationPromise = Promise.resolve();

  async decreasePlaybackRatePreference(options: StateUpdateOptionsWithoutEventName): Promise<void> {
    const oldRate = this._getSettings()?.playbackRate;
    const possibleNewRate =
      typeof oldRate === 'undefined'
        ? textToSpeechDefaultPlaybackRate
        : Array.from(PlaybackRates)
            .reverse()
            .find((rate) => rate < oldRate);
    if (typeof possibleNewRate !== 'number') {
      return;
    }
    return this.setPlaybackRatePreference(possibleNewRate, {
      ...options,
      eventName: 'settings-tts-playback-rate-decreased',
    });
  }

  async fetchTimestampForElement(docId: string, chunkId: string, elementIndex: number): Promise<number | undefined> {
    const doc = await database.collections.documents.findOne<FirstClassDocument>(docId);
    if (!doc) {
      throw new Error(`_buildTrack: can't find document with id ${docId}`);
    }

    await updateState(
      (state) => {
        if (state.ttsFetchingTimestamp) {
          throw new CancelStateUpdate();
        }
        state.ttsFetchingTimestamp = true;
      },
      { eventName: 'tts-timestamp-fetch-started', userInteraction: null, shouldCreateUserEvent: false },
    );

    const voice = this.getVoiceForDocument(doc);
    const versionEndpoint = TextToSpeechVoiceToApiVersion[voice];
    if (versionEndpoint !== 'v3') {
      return;
    }
    const url = `${getServerBaseUrl()}/reader/api/documents/${docId}/tts_stream_${versionEndpoint}_element_to_timestamp?voice=${voice}&elementIndex=${elementIndex}&chunk_id=${chunkId}`;
    const resp = await requestWithAuth(url, {
      credentials: 'include',
      method: 'GET',
      mode: 'cors',
    });
    // After 10 seconds, remove the loading state in case it didnt get removed otherwise
    setTimeout(() => {
      updateState(
        (state) => {
          if (!state.ttsFetchingTimestamp) {
            throw new CancelStateUpdate();
          }
          state.ttsFetchingTimestamp = false;
        },
        { eventName: 'tts-timestamp-fetch-ended', userInteraction: null, shouldCreateUserEvent: false },
      );
    }, 10000);

    const text = await resp.json();
    return text.start_timestamp;
  }

  getCurrentTTSLanguageForDoc(doc: FirstClassDocument | null): TTSLanguage {
    if (!doc) {
      return textToSpeechDefaultLanguage;
    }

    const language = this._convertLanguageToTTSLanguage(
      getDocumentLanguage(doc) || textToSpeechDefaultLanguage,
    );

    if (!language || !TextToSpeechVoicesByLanguage[language]) {
      return textToSpeechDefaultLanguage;
    }
    return language;
  }

  getVoiceForDocument(doc: FirstClassDocument | null): TextToSpeechVoice {
    const ttsSettings = this._getSettings();
    if (!ttsSettings || !ttsSettings.defaultVoiceSettings || !doc) {
      return this._getDefaultVoiceForLanguageAndGender(
        textToSpeechDefaultLanguage,
        textToSpeechDefaultGender,
      );
    }
    const currentTTSLanguageForDoc = this.getCurrentTTSLanguageForDoc(doc);
    // If a user has set a voice for this language, return that voice
    // Check that chosen voice is supported by current device
    const userChosenVoiceForLanguage = ttsSettings.chosenVoiceForLanguage[currentTTSLanguageForDoc];
    if (
      userChosenVoiceForLanguage &&
      TextToSpeechVoicesByLanguage[currentTTSLanguageForDoc].includes(userChosenVoiceForLanguage)
    ) {
      return userChosenVoiceForLanguage;
    }
    const defaultVoiceSettings = ttsSettings.defaultVoiceSettings;
    const defaultGender = defaultVoiceSettings.defaultGender;
    const defaultLanguage = defaultVoiceSettings.defaultLanguage;

    // Return either the default voice for language, or user default language and gender,
    // or if all else fails, the true default (female english)
    return (
      this._getDefaultVoiceForLanguageAndGender(currentTTSLanguageForDoc, defaultGender) ??
      // if there is no chosen voice, default to whichever voice is default for this language based on defaultVoiceSettings
      this._getDefaultVoiceForLanguageAndGender(defaultLanguage, defaultGender) ??
      // if not existent, select the voice that matches the default preferences for all TTS
      this._getDefaultVoiceForLanguageAndGender(textToSpeechDefaultLanguage, textToSpeechDefaultGender)
    );
  }

  getTTSWordBoundariesForVoice(
    boundaries:
      | ''
      | TransientDocumentData['tts']
      | undefined,
    voice: TextToSpeechVoice,
  ): {[chunkId: string]: WordBoundary[];} | undefined {
    if (!boundaries || !voice || !boundaries[voice]) {
      return;
    }
    if (TextToSpeechVoiceToApiVersion[voice] === 'v3') {
      return boundaries[voice].word_boundaries_v3_chunked;
    }
    return boundaries[voice].word_boundaries_v2_chunked;
  }

  isDocumentUsingUnrealSpeech(doc: FirstClassDocument): boolean {
    const voice = this.getVoiceForDocument(doc);
    return TextToSpeechVoiceToApiVersion[voice] === 'v3';
  }

  async increasePlaybackRatePreference(options: StateUpdateOptionsWithoutEventName): Promise<void> {
    const oldRate = this._getSettings()?.playbackRate;
    const possibleNewRate =
      typeof oldRate === 'undefined'
        ? textToSpeechDefaultPlaybackRate
        : PlaybackRates.find((rate) => rate > oldRate);
    if (typeof possibleNewRate !== 'number') {
      return;
    }
    return this.setPlaybackRatePreference(possibleNewRate, {
      ...options,
      eventName: 'settings-tts-playback-rate-increased',
    });
  }

  async jumpBackward(): Promise<void> {
    await this._jump(-15);
  }

  async jumpForward(): Promise<void> {
    await this._jump(15);
  }

  async modifyVolumePreference(
    action: 'decrease' | 'increase',
    options: StateUpdateOptionsWithoutEventName,
  ): Promise<void> {
    const oldVolume = this._getSettings()?.volume;
    let newVolume: number;
    if (typeof oldVolume === 'number') {
      let delta = 0.1;
      if (action === 'decrease') {
        delta *= -1;
      }
      newVolume = oldVolume + delta;
      if (newVolume < 0.05) {
        // Round down
        newVolume = 0;
      }
    } else {
      newVolume = textToSpeechDefaultVolume;
    }

    return this.setVolumePreference(newVolume, {
      ...options,
      eventName: `settings-tts-volume-${action}d`,
    });
  }

  async pause(): Promise<void> {
    await this._setIsPlaying(false);
  }

  async play(): Promise<void> {
    await this._setIsPlaying(true);
  }

  async playDocument(docId: string, startingChunkId: string, startPositionInChunk?: number): Promise<void> {
    await this._ensureDefaultPreferences();

    const tracks = await this._buildTracks(docId);
    if (tracks === undefined) {
      return;
    }
    await this._playTracks(tracks, startingChunkId, startPositionInChunk, docId);
  }

  async playOrPauseDocument(docId: string, chunkId: string) {
    const playingDocId = globalState.getState().tts?.playingDocId;
    if (docId !== playingDocId) {
      await this.playDocument(docId, chunkId);
      return;
    }
    switch (await this._getTrackPlayerState()) {
      case TrackPlayerState.Loading:
      case TrackPlayerState.Playing:
        await this.pause();
        break;
      case TrackPlayerState.Off:
        await this.playDocument(docId, chunkId);
        break;
      case TrackPlayerState.Paused:
        await this.play();
        break;
    }
  }

  async playOrPauseCurrentlyPlayingDocumentOrPlayNewDocument(
    chunkId?: string,
    newDocId?: FirstClassDocument['id'] | null,
  ): Promise<void> {
    if (globalState.getState().tts?.playingDocId) {
      await this.resumeOrPauseCurrentlyPlayingDocument();
    } else if (newDocId && chunkId) {
      await this.playDocument(newDocId, chunkId);
    }
  }

  async playOrStopDocument({
    docId,
    shouldPlayIfPaused,
    chunkId,
    startingPosition,
  }: {
    docId: string;
    shouldPlayIfPaused?: boolean;
    chunkId: string;
    startingPosition?: number;
  }) {
    const playingDocId = globalState.getState().tts?.playingDocId;
    if (docId !== playingDocId) {
      await this.playDocument(docId, chunkId, startingPosition);
      return;
    }
    switch (await this._getTrackPlayerState()) {
      case TrackPlayerState.Loading:
      case TrackPlayerState.Playing:
        await this.stop();
        break;
      case TrackPlayerState.Off:
        await this.playDocument(docId, chunkId, startingPosition);
        break;
      case TrackPlayerState.Paused:
        if (shouldPlayIfPaused) {
          await this.play();
        } else {
          await this.stop();
        }
        break;
    }
  }

  async resumeOrPauseCurrentlyPlayingDocument(): Promise<void> {
    const playingDocId = globalState.getState().tts?.playingDocId;
    if (!playingDocId) {
      throw new Error('There is no playing document');
    }
    const playingChunkId = globalState.getState().tts?.playingChunkId;
    if (!playingChunkId) {
      throw new Error('There is no playing chunk Id');
    }
    return this.playOrPauseDocument(playingDocId, playingChunkId);
  }

  async seek(position: number, chunkId?: string) {
    const playingChunkId = globalState.getState().tts?.playingChunkId;
    if (!playingChunkId && !chunkId) {
      throw new Error('Seek: There is no playing chunk Id');
    }
    if (chunkId && playingChunkId !== chunkId) {
      await this._skipToTrackByChunkId(chunkId);
    }
    let targetPosition = position;
    const currentTime = await this._getTrackPlayerPosition();
    if (targetPosition < 0 && currentTime <= 1.3) {
      const currentTrackIndex = await this._getCurrentTrackIndex();
      if (currentTrackIndex > 0) {
        console.log('Skip in seek');
        await this._skipToTrackByIndex(Math.max(currentTrackIndex - 1, 0));
        const trackLength = await this._getCurrentTrackLength();
        targetPosition = trackLength - 15;
      }
    }
    await this.seekWithinChunkAndRetryUntilSuccessful(Math.max(targetPosition, 0));
  }

  async setIsAutoScrollingEnabled(isEnabled: boolean) {
    await updateState(
      ({ tts }) => {
        if (!tts || tts.autoScrolling === isEnabled) {
          throw new CancelStateUpdate();
        }
        tts.autoScrolling = isEnabled;
      },
      {
        eventName: 'tts-auto-scrolling-set',
        userInteraction: null,
      },
    );
  }

  async setPlaybackRatePreference(
    rate: PlaybackRate,
    options: StateUpdateOptionsWithoutEventName & Partial<Pick<StateUpdateOptions, 'eventName'>>,
  ): Promise<void> {
    const prevRate = this._getSettings()?.playbackRate;
    if (rate === prevRate) {
      return;
    }
    await updateState(
      ({ persistent: { settings } }) => {
        if (!settings.tts_v2) {
          throw new Error('TtsController.setPlaybackRatePreference: tts preferences do not exist');
        }
        settings.tts_v2.playbackRate = rate;
      },
      {
        eventName: 'settings-tts-playback-rate-set',
        isUndoable: false,
        ...options,
      },
    );
  }

  async setVolumePreference(
    volume: number,
    options: StateUpdateOptionsWithoutEventName & Partial<Pick<StateUpdateOptions, 'eventName'>>,
  ): Promise<void> {
    const previousVolume = this._getSettings()?.volume;
    const fixedVolume = Math.max(0, Math.min(volume, 1));
    if (fixedVolume !== previousVolume) {
      await updateState(
        ({ persistent: { settings } }) => {
          if (!settings.tts_v2) {
            throw new Error('TtsController.setPlaybackRatePreference: tts preferences do not exist');
          }
          settings.tts_v2.volume = fixedVolume;
        },
        {
          eventName: 'settings-tts-volume-set',
          isUndoable: false,
          ...options,
        },
      );
    }

    // If it becomes muted, pause (to save money)
    if (!fixedVolume) {
      this.pause();
    }
  }

  async setVoicePreferenceAndLanguage({
    documentId,
    language,
    userInteraction,
    voice,
  }: {
    documentId: FirstClassDocument['id'];
    language: TTSLanguage;
    userInteraction: string | null;
    voice: TextToSpeechVoice;
  }) {
    await this._ensureDefaultPreferences();
    await updateState(
      (state) => {
        if (!state.persistent.settings.tts_v2) {
          throw new Error('no tts in persistentState???'); // should never happen
        }
        if (state.persistent.settings.tts_v2.chosenVoiceForLanguage[language] === voice) {
          throw new CancelStateUpdate();
        }
        state.persistent.settings.tts_v2.chosenVoiceForLanguage[language] = voice;
      },
      {
        eventName: 'tts-voice-updated-for-language',
        userInteraction: null,
      },
    );

    await overrideLanguage(documentId, { language }, { userInteraction });
    await this._reloadTrack();
  }

  async setTrackPlayerInfo(info: TextToSpeechInfo['trackPlayerInfo']) {
    if (!globalState.getState().tts) {
      return;
    }

    await updateState(
      (state) => {
        if (!state.tts) {
          throw new Error('!state.tts');
        }
        state.tts.trackPlayerInfo = info;
      },
      {
        eventName: 'tts-track-player-info-set',
        isUndoable: false,
        userInteraction: null,
      },
    );
  }

  async stop(): Promise<void> {
    await this._resetTrackPlayer();
    await updateState(
      (state) => {
        if (!state.tts) {
          throw new CancelStateUpdate();
        }
        state.tts = null;
      },
      {
        eventName: 'tts-stopped',
        userInteraction: null,
      },
    );
  }

  async switchVoiceToV2(docParam: FirstClassDocument | null, userInteraction: string | null) {
    const docId = globalState.getState().tts?.playingDocId;
    const doc = docParam ?? await database.collections.documents.findOne<FirstClassDocument>(docId);
    if (!doc) {
      return;
    }
    const voice = this.getVoiceForDocument(doc);
    if (TextToSpeechVoiceToApiVersion[voice] === 'v2') {
      return;
    }
    const gender = TextToSpeechGenderByVoice[voice];
    const newVoice = gender === 'male' ? TextToSpeechVoice.Guy : TextToSpeechVoice.Aria;
    await this.setVoicePreferenceAndLanguage({
      documentId: doc.id,
      language: textToSpeechDefaultLanguage,
      voice: newVoice,
      userInteraction,
    });
    createToast({
      content: 'Voice updated',
      category: 'success',
    });
  }

  async switchVoiceToV3(doc: FirstClassDocument, userInteraction: string | null) {
    if (!doc) {
      return;
    }
    const voice = this.getVoiceForDocument(doc);
    if (TextToSpeechVoiceToApiVersion[voice] === 'v3') {
      return;
    }
    const gender = TextToSpeechGenderByVoice[voice];
    const newVoice = this._getDefaultVoiceForLanguageAndGender(textToSpeechDefaultLanguage, gender);
    await this.setVoicePreferenceAndLanguage({
      documentId: doc.id,
      language: textToSpeechDefaultLanguage,
      voice: newVoice,
      userInteraction: 'todo', // TODO
    });
    createToast({
      content: 'Voice updated',
      category: 'success',
    });
  }

  async toggleIsPlaying(): Promise<boolean> {
    return this._setIsPlaying(!await this._isTrackPlayerPlaying());
  }

  /*
    TrackPlayer (on react-native at least) does not always successfully seekTo a position we request, (perhaps it tries
    its best and fails).
    This helps us ensure that after a certain delay we are at the right position
  */
  async seekWithinChunkAndRetryUntilSuccessful(targetPosition: number, numberOfRetries = 0) {
    if (numberOfRetries > 500) {
      return;
    }
    await this._seekTrackPlayerWithinChunk(targetPosition);
    const newPosition = await this._getTrackPlayerPosition();
    if (Math.abs(newPosition - targetPosition) < 2) {
      return;
    }
    await delay(20);
    await this.seekWithinChunkAndRetryUntilSuccessful(targetPosition, numberOfRetries + 1);
  }

  abstract updateCurrentPlayingTrackInState(): Promise<void>;

  abstract _skipToTrackByChunkId(chunkId: string): Promise<void>;
  abstract _getCurrentTrackIndex(): Promise<number>;
  abstract _skipToTrackByIndex(index: number): Promise<void>;
  abstract _getCurrentTrackLength(): Promise<number>;

  abstract _setQueueForTrackPlayer(tracks: TTrack[]): Promise<void>;

  async _buildTracks(
    docIdArgument: string | undefined,
  ): Promise<TTrack[] | undefined> {
    const docId = docIdArgument ?? globalState.getState().tts?.playingDocId;
    if (!docId) {
      throw new Error('_buildTrack: need a docId');
    }
    const doc = await database.collections.documents.findOne<FirstClassDocument>(docId);
    const docTransientData = globalState.getState().transientDocumentsData[docId];
    const docSpine = docTransientData.spine;
    const allChunkKeys = docSpine ? sortDocumentSpineByChunkIds(docSpine) : [UNCHUNKED_DOCUMENT_CHUNK_ID];
    if (!doc) {
      throw new Error(`_buildTrack: can't find document with id ${docId}`);
    }


    const currentVoice = this.getVoiceForDocument(doc);

    const tracks: TTrack[] = [];
    for (const chunkId of allChunkKeys) {
      const chunkUrl = this._buildTrackUrl(doc, currentVoice, chunkId);
      tracks.push({
        artist: doc.author || '',
        artwork: doc.image_url || '',
        title: doc.title,
        url: chunkUrl,
        docId,
        chunkId,
      } as TTrack);
    }
    return tracks;
  }

  _buildTrackUrl(
    doc: FirstClassDocument,
    voice: TextToSpeechVoice,
    chunkId: string,
  ): string | undefined {
    const versionEndpoint = TextToSpeechVoiceToApiVersion[voice];
    return `${getServerBaseUrl()}/reader/api/documents/${
      doc.id
    }/tts_stream_${versionEndpoint}.mp3?voice=${voice}&chunkId=${chunkId}`;
  }

  _convertLanguageToTTSLanguage(
    languageCode: DocumentWithLanguage['language'],
  ): TTSLanguage | undefined {
    if (!languageCode) {
      return undefined;
    }
    const convertedLanguageCode = languageCode.split('-')[0];
    if (TextToSpeechVoicesByLanguage[convertedLanguageCode]) {
      return convertedLanguageCode as TTSLanguage;
    }
    return undefined;
  }

  async _enableAutoScrollingIfSettingIsEnabled() {
    if (globalState.getState().tts?.autoScrolling) {
      return this.setIsAutoScrollingEnabled(true);
    }
  }

  async _ensureDefaultPreferences(): Promise<void> {
    if (this._getSettings()) {
      return;
    }
    await updateState(
      ({ persistent: { settings } }) => {
        settings.tts_v2 = {
          chosenVoiceForLanguage: {},
          defaultVoiceSettings: {
            defaultGender: textToSpeechDefaultGender,
            defaultLanguage: textToSpeechDefaultLanguage,
          },
          playbackRate: textToSpeechDefaultPlaybackRate,
        };
      },
      { userInteraction: null, eventName: 'tts-default-settings-set', isUndoable: false },
    );
  }

  _getDefaultVoiceForLanguageAndGender(
    language: TTSLanguage,
    gender: TextToSpeechGender,
  ): TextToSpeechVoice {
    const allVoicesForLanguage = TextToSpeechVoicesByLanguage[language];
    return allVoicesForLanguage.filter((voice) => TextToSpeechGenderByVoice[voice] === gender)[0];
  }

  _getSettings(): TextToSpeechSettings | undefined {
    return globalState.getState().persistent.settings.tts_v2;
  }

  abstract _getTrackPlayerPosition(): Promise<number>;
  abstract _getTrackPlayerState(): Promise<TrackPlayerState>;

  async _isTrackPlayerPlaying(): Promise<boolean> {
    return await this._getTrackPlayerState() === TrackPlayerState.Playing;
  }

  async _jump(seconds: number) {
    const position = await Promise.resolve(this._getTrackPlayerPosition());
    await this.seek(position + seconds);
    await this._enableAutoScrollingIfSettingIsEnabled();
  }

  async _onLanguageUpdated(documentId: FirstClassDocument['id'], language: TTSLanguage) {
    // Do nothing
  }

  abstract _pauseTrackPlayer(): Promise<void>;

  async _playTracks(tracks: TTrack[], startingChunkId: string, startPositionInChunk: number | undefined, docId?: string) {
    await this._resetTrackPlayer();

    await this._setQueueForTrackPlayer(tracks);
    await this._skipToTrackByChunkId(startingChunkId);

    await updateState(
      (state) => {
        const playingDocId = docId ?? state.tts?.playingDocId;
        if (!playingDocId) {
          exceptionHandler.captureException(new Error('TtsControl: playingDocId is undefined'), {
            extra: {
              tts: state.tts,
              ttsSettings: state.persistent.settings.tts_v2,
              tracks,
              startPosition: startPositionInChunk,
            },
          });
          return;
        }
        state.ttsFetchingTimestamp = false;
        state.tts = {
          autoScrolling: state.tts?.autoScrolling ?? true,
          startingPositionInChunk: startPositionInChunk ?? 0,
          playingDocId,
          playingChunkId: startingChunkId,
          currentlyPlayingListQuery: state.focusedDocumentListQuery,
        };
      },
      {
        eventName: 'tts-document-playback-started',
        userInteraction: null,
      },
    );

    if (startPositionInChunk) {
      await this.seekWithinChunkAndRetryUntilSuccessful(startPositionInChunk);
    }
    await this.play();
  }

  async _reloadTrack(newPosition?: number) {
    const position = newPosition ?? await this._getTrackPlayerPosition();
    const tracks = await this._buildTracks(undefined);
    const chunkId = globalState.getState().tts?.playingChunkId;
    if (!tracks || !chunkId) {
      return;
    }
    await this._playTracks(tracks, chunkId, position);
  }

  abstract _resetTrackPlayer(): Promise<void>;

  abstract _seekTrackPlayerWithinChunk(position: number): Promise<void>;

  async _setIsPlaying(isPlaying: boolean): Promise<boolean> {
    const state = await this._getTrackPlayerState();
    if (isPlaying) {
      // If it's muted, turn it up
      if (this._getSettings()?.volume === 0) {
        await this.setVolumePreference(0.3, { userInteraction: null });
      }

      if (state !== TrackPlayerState.Playing) {
        await this._startPlayingTrackPlayer();
        await this._enableAutoScrollingIfSettingIsEnabled();
      }
      return true;
    } else {
      if (state !== TrackPlayerState.Paused) {
        await this._pauseTrackPlayer();
      }
      return false;
    }
  }

  abstract _startPlayingTrackPlayer(): Promise<void>;
}

export default AbstractTtsController;
