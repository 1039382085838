import { isHTMLElement } from '../../../typeValidators';
import getRgbFromColor from './getRgbFromColor';

function getBackgroundColorFromNode(node: Node): string | undefined {
  if (!isHTMLElement(node)) {
    return;
  }

  return window.getComputedStyle(node).getPropertyValue('background-color').trim();
}

export default function getBackgroundColorRgbFromNode(node: Node): [number, number, number] | undefined {
  const backgroundColor = getBackgroundColorFromNode(node);
  return getRgbFromColor(backgroundColor);
}
