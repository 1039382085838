// SOURCE for the voice info: https://learn.microsoft.com/en-us/azure/cognitive-services/speech-service/rest-text-to-speech?tabs=streaming#get-a-list-of-voices

import type { MangoQuery } from 'rxdb/dist/types/types';

import type { AnyDocument, FirstClassDocument } from './index';

// (and some REPL-fu).
export enum TextToSpeechVoice {
  Guy = 'en-US-GuyNeural',
  Aria = 'en-US-AriaNeural',

  UnrealDan = 'Dan',
  UnrealLiv = 'Liv',
  UnrealDanV7 = 'DanV7',
  UnrealLivV7 = 'LivV7',
  UnrealWill = 'Will',
  UnrealAmy = 'Amy',
  UnrealZoe = 'Zoe',
  KokoroDefault = 'af',
  KokoroBella = 'af_bella',
  KokoroSarah = 'af_sarah',
  KokoroAdam = 'am_adam',
  KokoroMichael = 'am_michael',
  KokoroEmma = 'bf_emma',
  KokoroIsabella = 'bf_isabella',
  KokoroGeorge = 'bm_george',
  KokoroLewis = 'bm_lewis',
  KokoroNicole = 'af_nicole',
  KokoroSky = 'af_sky',

  Adri = 'af-ZA-AdriNeural',
  Willem = 'af-ZA-WillemNeural',
  Ameha = 'am-ET-AmehaNeural',
  Mekdes = 'am-ET-MekdesNeural',
  Fatima = 'ar-AE-FatimaNeural',
  Hamdan = 'ar-AE-HamdanNeural',
  Ali = 'ar-BH-AliNeural',
  Laila = 'ar-BH-LailaNeural',
  Amina = 'ar-DZ-AminaNeural',
  Ismael = 'ar-DZ-IsmaelNeural',
  Salma = 'ar-EG-SalmaNeural',
  Shakir = 'ar-EG-ShakirNeural',
  Bassel = 'ar-IQ-BasselNeural',
  Rana = 'ar-IQ-RanaNeural',
  Sana = 'ar-JO-SanaNeural',
  Taim = 'ar-JO-TaimNeural',
  Fahed = 'ar-KW-FahedNeural',
  Noura = 'ar-KW-NouraNeural',
  Layla = 'ar-LB-LaylaNeural',
  Rami = 'ar-LB-RamiNeural',
  Iman = 'ar-LY-ImanNeural',
  Omar = 'ar-LY-OmarNeural',
  Jamal = 'ar-MA-JamalNeural',
  Mouna = 'ar-MA-MounaNeural',
  Abdullah = 'ar-OM-AbdullahNeural',
  Aysha = 'ar-OM-AyshaNeural',
  Amal = 'ar-QA-AmalNeural',
  Moaz = 'ar-QA-MoazNeural',
  Hamed = 'ar-SA-HamedNeural',
  Zariyah = 'ar-SA-ZariyahNeural',
  Amany = 'ar-SY-AmanyNeural',
  Laith = 'ar-SY-LaithNeural',
  Hedi = 'ar-TN-HediNeural',
  Reem = 'ar-TN-ReemNeural',
  Maryam = 'ar-YE-MaryamNeural',
  Saleh = 'ar-YE-SalehNeural',
  Babek = 'az-AZ-BabekNeural',
  Banu = 'az-AZ-BanuNeural',
  Borislav = 'bg-BG-BorislavNeural',
  Kalina = 'bg-BG-KalinaNeural',
  Nabanita = 'bn-BD-NabanitaNeural',
  Pradeep = 'bn-BD-PradeepNeural',
  Bashkar = 'bn-IN-BashkarNeural',
  Tanishaa = 'bn-IN-TanishaaNeural',
  Goran = 'bs-BA-GoranNeural',
  Vesna = 'bs-BA-VesnaNeural',
  Joana = 'ca-ES-JoanaNeural',
  Enric = 'ca-ES-EnricNeural',
  Antonin = 'cs-CZ-AntoninNeural',
  Vlasta = 'cs-CZ-VlastaNeural',
  Aled = 'cy-GB-AledNeural',
  Nia = 'cy-GB-NiaNeural',
  Christel = 'da-DK-ChristelNeural',
  Jeppe = 'da-DK-JeppeNeural',
  Ingrid = 'de-AT-IngridNeural',
  Jonas = 'de-AT-JonasNeural',
  Jan = 'de-CH-JanNeural',
  Leni = 'de-CH-LeniNeural',
  Katja = 'de-DE-KatjaNeural',
  Killian = 'de-DE-KillianNeural',
  Athina = 'el-GR-AthinaNeural',
  Nestoras = 'el-GR-NestorasNeural',
  Annette = 'en-AU-AnnetteNeural',
  Darren = 'en-AU-DarrenNeural',
  Clara = 'en-CA-ClaraNeural',
  Liam = 'en-CA-LiamNeural',
  Libby = 'en-GB-LibbyNeural',
  Alfie = 'en-GB-AlfieNeural',
  Sam = 'en-HK-SamNeural',
  Yan = 'en-HK-YanNeural',
  Connor = 'en-IE-ConnorNeural',
  Emily = 'en-IE-EmilyNeural',
  Neerja = 'en-IN-NeerjaNeural',
  Prabhat = 'en-IN-PrabhatNeural',
  Asilia = 'en-KE-AsiliaNeural',
  Chilemba = 'en-KE-ChilembaNeural',
  Abeo = 'en-NG-AbeoNeural',
  Ezinne = 'en-NG-EzinneNeural',
  Mitchell = 'en-NZ-MitchellNeural',
  Molly = 'en-NZ-MollyNeural',
  James = 'en-PH-JamesNeural',
  Rosa = 'en-PH-RosaNeural',
  Luna = 'en-SG-LunaNeural',
  Wayne = 'en-SG-WayneNeural',
  Elimu = 'en-TZ-ElimuNeural',
  Imani = 'en-TZ-ImaniNeural',
  Jenny = 'en-US-JennyNeural',
  Leah = 'en-ZA-LeahNeural',
  Luke = 'en-ZA-LukeNeural',
  Elena = 'es-AR-ElenaNeural',
  Tomas = 'es-AR-TomasNeural',
  Marcelo = 'es-BO-MarceloNeural',
  Sofia = 'es-BO-SofiaNeural',
  Catalina = 'es-CL-CatalinaNeural',
  Lorenzo = 'es-CL-LorenzoNeural',
  Gonzalo = 'es-CO-GonzaloNeural',
  Salome = 'es-CO-SalomeNeural',
  Juan = 'es-CR-JuanNeural',
  Maria = 'es-CR-MariaNeural',
  Belkys = 'es-CU-BelkysNeural',
  Manuel = 'es-CU-ManuelNeural',
  Emilio = 'es-DO-EmilioNeural',
  Ramona = 'es-DO-RamonaNeural',
  Andrea = 'es-EC-AndreaNeural',
  Luis = 'es-EC-LuisNeural',
  Abril = 'es-ES-AbrilNeural',
  Alvaro = 'es-ES-AlvaroNeural',
  Javier = 'es-GQ-JavierNeural',
  Teresa = 'es-GQ-TeresaNeural',
  Andres = 'es-GT-AndresNeural',
  Marta = 'es-GT-MartaNeural',
  Carlos = 'es-HN-CarlosNeural',
  Karla = 'es-HN-KarlaNeural',
  Beatriz = 'es-MX-BeatrizNeural',
  Cecilio = 'es-MX-CecilioNeural',
  Federico = 'es-NI-FedericoNeural',
  Yolanda = 'es-NI-YolandaNeural',
  Margarita = 'es-PA-MargaritaNeural',
  Roberto = 'es-PA-RobertoNeural',
  Alex = 'es-PE-AlexNeural',
  Camila = 'es-PE-CamilaNeural',
  Karina = 'es-PR-KarinaNeural',
  Victor = 'es-PR-VictorNeural',
  Mario = 'es-PY-MarioNeural',
  Tania = 'es-PY-TaniaNeural',
  Lorena = 'es-SV-LorenaNeural',
  Rodrigo = 'es-SV-RodrigoNeural',
  Alonso = 'es-US-AlonsoNeural',
  Paloma = 'es-US-PalomaNeural',
  Mateo = 'es-UY-MateoNeural',
  Valentina = 'es-UY-ValentinaNeural',
  Paola = 'es-VE-PaolaNeural',
  Sebastian = 'es-VE-SebastianNeural',
  Anu = 'et-EE-AnuNeural',
  Kert = 'et-EE-KertNeural',
  Ainhoa = 'eu-ES-AinhoaNeural',
  Ander = 'eu-ES-AnderNeural',
  Dilara = 'fa-IR-DilaraNeural',
  Farid = 'fa-IR-FaridNeural',
  Selma = 'fi-FI-SelmaNeural',
  Harri = 'fi-FI-HarriNeural',
  Angelo = 'fil-PH-AngeloNeural',
  Blessica = 'fil-PH-BlessicaNeural',
  Charline = 'fr-BE-CharlineNeural',
  Gerard = 'fr-BE-GerardNeural',
  Sylvie = 'fr-CA-SylvieNeural',
  Antoine = 'fr-CA-AntoineNeural',
  Ariane = 'fr-CH-ArianeNeural',
  Fabrice = 'fr-CH-FabriceNeural',
  Alain = 'fr-FR-AlainNeural',
  Brigitte = 'fr-FR-BrigitteNeural',
  Colm = 'ga-IE-ColmNeural',
  Orla = 'ga-IE-OrlaNeural',
  Roi = 'gl-ES-RoiNeural',
  Sabela = 'gl-ES-SabelaNeural',
  Dhwani = 'gu-IN-DhwaniNeural',
  Niranjan = 'gu-IN-NiranjanNeural',
  Avri = 'he-IL-AvriNeural',
  Hila = 'he-IL-HilaNeural',
  Madhur = 'hi-IN-MadhurNeural',
  Swara = 'hi-IN-SwaraNeural',
  Gabrijela = 'hr-HR-GabrijelaNeural',
  Srecko = 'hr-HR-SreckoNeural',
  Noemi = 'hu-HU-NoemiNeural',
  Tamas = 'hu-HU-TamasNeural',
  Anahit = 'hy-AM-AnahitNeural',
  Hayk = 'hy-AM-HaykNeural',
  Ardi = 'id-ID-ArdiNeural',
  Gadis = 'id-ID-GadisNeural',
  Gudrun = 'is-IS-GudrunNeural',
  Gunnar = 'is-IS-GunnarNeural',
  Isabella = 'it-IT-IsabellaNeural',
  Benigno = 'it-IT-BenignoNeural',
  Nanami = 'ja-JP-NanamiNeural',
  Daichi = 'ja-JP-DaichiNeural',
  Dimas = 'jv-ID-DimasNeural',
  Siti = 'jv-ID-SitiNeural',
  Eka = 'ka-GE-EkaNeural',
  Giorgi = 'ka-GE-GiorgiNeural',
  Aigul = 'kk-KZ-AigulNeural',
  Daulet = 'kk-KZ-DauletNeural',
  Piseth = 'km-KH-PisethNeural',
  Sreymom = 'km-KH-SreymomNeural',
  Gagan = 'kn-IN-GaganNeural',
  Sapna = 'kn-IN-SapnaNeural',
  SunHi = 'ko-KR-SunHiNeural',
  InJoon = 'ko-KR-InJoonNeural',
  Chanthavong = 'lo-LA-ChanthavongNeural',
  Keomany = 'lo-LA-KeomanyNeural',
  Leonas = 'lt-LT-LeonasNeural',
  Ona = 'lt-LT-OnaNeural',
  Everita = 'lv-LV-EveritaNeural',
  Nils = 'lv-LV-NilsNeural',
  Aleksandar = 'mk-MK-AleksandarNeural',
  Marija = 'mk-MK-MarijaNeural',
  Midhun = 'ml-IN-MidhunNeural',
  Sobhana = 'ml-IN-SobhanaNeural',
  Bataa = 'mn-MN-BataaNeural',
  Yesui = 'mn-MN-YesuiNeural',
  Aarohi = 'mr-IN-AarohiNeural',
  Manohar = 'mr-IN-ManoharNeural',
  Osman = 'ms-MY-OsmanNeural',
  Yasmin = 'ms-MY-YasminNeural',
  Grace = 'mt-MT-GraceNeural',
  Joseph = 'mt-MT-JosephNeural',
  Nilar = 'my-MM-NilarNeural',
  Thiha = 'my-MM-ThihaNeural',
  Pernille = 'nb-NO-PernilleNeural',
  Finn = 'nb-NO-FinnNeural',
  Hemkala = 'ne-NP-HemkalaNeural',
  Sagar = 'ne-NP-SagarNeural',
  Arnaud = 'nl-BE-ArnaudNeural',
  Dena = 'nl-BE-DenaNeural',
  Colette = 'nl-NL-ColetteNeural',
  Maarten = 'nl-NL-MaartenNeural',
  Agnieszka = 'pl-PL-AgnieszkaNeural',
  Marek = 'pl-PL-MarekNeural',
  GulNawaz = 'ps-AF-GulNawazNeural',
  Latifa = 'ps-AF-LatifaNeural',
  Francisca = 'pt-BR-FranciscaNeural',
  Donato = 'pt-BR-DonatoNeural',
  Duarte = 'pt-PT-DuarteNeural',
  Fernanda = 'pt-PT-FernandaNeural',
  Alina = 'ro-RO-AlinaNeural',
  Emil = 'ro-RO-EmilNeural',
  Svetlana = 'ru-RU-SvetlanaNeural',
  Dmitry = 'ru-RU-DmitryNeural',
  Sameera = 'si-LK-SameeraNeural',
  Thilini = 'si-LK-ThiliniNeural',
  Lukas = 'sk-SK-LukasNeural',
  Viktoria = 'sk-SK-ViktoriaNeural',
  Petra = 'sl-SI-PetraNeural',
  Rok = 'sl-SI-RokNeural',
  Muuse = 'so-SO-MuuseNeural',
  Ubax = 'so-SO-UbaxNeural',
  Anila = 'sq-AL-AnilaNeural',
  Ilir = 'sq-AL-IlirNeural',
  Nicholas = 'sr-RS-NicholasNeural',
  Sophie = 'sr-RS-SophieNeural',
  Jajang = 'su-ID-JajangNeural',
  Tuti = 'su-ID-TutiNeural',
  Sofie = 'sv-SE-SofieNeural',
  Mattias = 'sv-SE-MattiasNeural',
  Rafiki = 'sw-KE-RafikiNeural',
  Zuri = 'sw-KE-ZuriNeural',
  Daudi = 'sw-TZ-DaudiNeural',
  Rehema = 'sw-TZ-RehemaNeural',
  Pallavi = 'ta-IN-PallaviNeural',
  Valluvar = 'ta-IN-ValluvarNeural',
  Kumar = 'ta-LK-KumarNeural',
  Saranya = 'ta-LK-SaranyaNeural',
  Kani = 'ta-MY-KaniNeural',
  Surya = 'ta-MY-SuryaNeural',
  Anbu = 'ta-SG-AnbuNeural',
  Venba = 'ta-SG-VenbaNeural',
  Mohan = 'te-IN-MohanNeural',
  Shruti = 'te-IN-ShrutiNeural',
  Premwadee = 'th-TH-PremwadeeNeural',
  Niwat = 'th-TH-NiwatNeural',
  Ahmet = 'tr-TR-AhmetNeural',
  Emel = 'tr-TR-EmelNeural',
  Ostap = 'uk-UA-OstapNeural',
  Polina = 'uk-UA-PolinaNeural',
  Gul = 'ur-IN-GulNeural',
  Salman = 'ur-IN-SalmanNeural',
  Asad = 'ur-PK-AsadNeural',
  Uzma = 'ur-PK-UzmaNeural',
  Madina = 'uz-UZ-MadinaNeural',
  Sardor = 'uz-UZ-SardorNeural',
  HoaiMy = 'vi-VN-HoaiMyNeural',
  NamMinh = 'vi-VN-NamMinhNeural',
  Xiaotong = 'wuu-CN-XiaotongNeural',
  Yunzhe = 'wuu-CN-YunzheNeural',
  XiaoMin = 'yue-CN-XiaoMinNeural',
  YunSong = 'yue-CN-YunSongNeural',
  Xiaoxiao = 'zh-CN-XiaoxiaoNeural',
  Yunyang = 'zh-CN-YunyangNeural',
  Yundeng = 'zh-CN-henan-YundengNeural',
  Xiaobei = 'zh-CN-liaoning-XiaobeiNeural',
  Xiaoni = 'zh-CN-shaanxi-XiaoniNeural',
  Yunxiang = 'zh-CN-shandong-YunxiangNeural',
  Yunxi = 'zh-CN-sichuan-YunxiNeural',
  HiuMaan = 'zh-HK-HiuMaanNeural',
  WanLung = 'zh-HK-WanLungNeural',
  HsiaoChen = 'zh-TW-HsiaoChenNeural',
  YunJhe = 'zh-TW-YunJheNeural',
  Thando = 'zu-ZA-ThandoNeural',
  Themba = 'zu-ZA-ThembaNeural',
}

export const TextToSpeechDisplayNameByVoice: { [voice in TextToSpeechVoice]?: string } = {
  [TextToSpeechVoice.Guy]: 'Finley',
  [TextToSpeechVoice.Aria]: 'Rey',
  [TextToSpeechVoice.UnrealDan]: 'Dan',
  [TextToSpeechVoice.UnrealLiv]: 'Liv',
  [TextToSpeechVoice.UnrealDanV7]: 'Daniel',
  [TextToSpeechVoice.UnrealLivV7]: 'Olivia',
  [TextToSpeechVoice.UnrealWill]: 'Will',
  [TextToSpeechVoice.UnrealAmy]: 'Amy',
  [TextToSpeechVoice.UnrealZoe]: 'Zoe',
  [TextToSpeechVoice.KokoroDefault]: 'Claire',
  [TextToSpeechVoice.KokoroBella]: 'Bella',
  [TextToSpeechVoice.KokoroSarah]: 'Sarah',
  [TextToSpeechVoice.KokoroAdam]: 'Adam',
  [TextToSpeechVoice.KokoroMichael]: 'Michael',
  [TextToSpeechVoice.KokoroEmma]: 'Emma',
  [TextToSpeechVoice.KokoroIsabella]: 'Isabella',
  [TextToSpeechVoice.KokoroGeorge]: 'George',
  [TextToSpeechVoice.KokoroLewis]: 'Lewis',
  [TextToSpeechVoice.KokoroNicole]: 'Nicole',
  [TextToSpeechVoice.KokoroSky]: 'Sky',
};

export type TextToSpeechGender = 'male' | 'female';
export const TextToSpeechGenderByVoice: { [voice in TextToSpeechVoice]: TextToSpeechGender } = {
  [TextToSpeechVoice.UnrealDan]: 'male',
  [TextToSpeechVoice.UnrealLiv]: 'female',
  [TextToSpeechVoice.UnrealDanV7]: 'male',
  [TextToSpeechVoice.UnrealLivV7]: 'female',
  [TextToSpeechVoice.UnrealWill]: 'male',
  [TextToSpeechVoice.UnrealAmy]: 'female',
  [TextToSpeechVoice.UnrealZoe]: 'female',
  [TextToSpeechVoice.KokoroDefault]: 'female',
  [TextToSpeechVoice.KokoroBella]: 'female',
  [TextToSpeechVoice.KokoroSarah]: 'female',
  [TextToSpeechVoice.KokoroAdam]: 'male',
  [TextToSpeechVoice.KokoroMichael]: 'male',
  [TextToSpeechVoice.KokoroEmma]: 'female',
  [TextToSpeechVoice.KokoroIsabella]: 'female',
  [TextToSpeechVoice.KokoroGeorge]: 'male',
  [TextToSpeechVoice.KokoroLewis]: 'male',
  [TextToSpeechVoice.KokoroNicole]: 'female',
  [TextToSpeechVoice.KokoroSky]: 'female',

  [TextToSpeechVoice.Guy]: 'male',
  [TextToSpeechVoice.Aria]: 'female',

  [TextToSpeechVoice.Adri]: 'female',
  [TextToSpeechVoice.Willem]: 'male',
  [TextToSpeechVoice.Ameha]: 'male',
  [TextToSpeechVoice.Mekdes]: 'female',
  [TextToSpeechVoice.Fatima]: 'female',
  [TextToSpeechVoice.Hamdan]: 'male',
  [TextToSpeechVoice.Ali]: 'male',
  [TextToSpeechVoice.Laila]: 'female',
  [TextToSpeechVoice.Amina]: 'female',
  [TextToSpeechVoice.Ismael]: 'male',
  [TextToSpeechVoice.Salma]: 'female',
  [TextToSpeechVoice.Shakir]: 'male',
  [TextToSpeechVoice.Bassel]: 'male',
  [TextToSpeechVoice.Rana]: 'female',
  [TextToSpeechVoice.Sana]: 'female',
  [TextToSpeechVoice.Taim]: 'male',
  [TextToSpeechVoice.Fahed]: 'male',
  [TextToSpeechVoice.Noura]: 'female',
  [TextToSpeechVoice.Layla]: 'female',
  [TextToSpeechVoice.Rami]: 'male',
  [TextToSpeechVoice.Iman]: 'female',
  [TextToSpeechVoice.Omar]: 'male',
  [TextToSpeechVoice.Jamal]: 'male',
  [TextToSpeechVoice.Mouna]: 'female',
  [TextToSpeechVoice.Abdullah]: 'male',
  [TextToSpeechVoice.Aysha]: 'female',
  [TextToSpeechVoice.Amal]: 'female',
  [TextToSpeechVoice.Moaz]: 'male',
  [TextToSpeechVoice.Hamed]: 'male',
  [TextToSpeechVoice.Zariyah]: 'female',
  [TextToSpeechVoice.Amany]: 'female',
  [TextToSpeechVoice.Laith]: 'male',
  [TextToSpeechVoice.Hedi]: 'male',
  [TextToSpeechVoice.Reem]: 'female',
  [TextToSpeechVoice.Maryam]: 'female',
  [TextToSpeechVoice.Saleh]: 'male',
  [TextToSpeechVoice.Babek]: 'male',
  [TextToSpeechVoice.Banu]: 'female',
  [TextToSpeechVoice.Borislav]: 'male',
  [TextToSpeechVoice.Kalina]: 'female',
  [TextToSpeechVoice.Nabanita]: 'female',
  [TextToSpeechVoice.Pradeep]: 'male',
  [TextToSpeechVoice.Bashkar]: 'male',
  [TextToSpeechVoice.Tanishaa]: 'female',
  [TextToSpeechVoice.Goran]: 'male',
  [TextToSpeechVoice.Vesna]: 'female',
  [TextToSpeechVoice.Joana]: 'female',
  [TextToSpeechVoice.Enric]: 'male',
  [TextToSpeechVoice.Antonin]: 'male',
  [TextToSpeechVoice.Vlasta]: 'female',
  [TextToSpeechVoice.Aled]: 'male',
  [TextToSpeechVoice.Nia]: 'female',
  [TextToSpeechVoice.Christel]: 'female',
  [TextToSpeechVoice.Jeppe]: 'male',
  [TextToSpeechVoice.Ingrid]: 'female',
  [TextToSpeechVoice.Jonas]: 'male',
  [TextToSpeechVoice.Jan]: 'male',
  [TextToSpeechVoice.Leni]: 'female',
  [TextToSpeechVoice.Katja]: 'female',
  [TextToSpeechVoice.Killian]: 'male',
  [TextToSpeechVoice.Athina]: 'female',
  [TextToSpeechVoice.Nestoras]: 'male',
  [TextToSpeechVoice.Annette]: 'female',
  [TextToSpeechVoice.Darren]: 'male',
  [TextToSpeechVoice.Clara]: 'female',
  [TextToSpeechVoice.Liam]: 'male',
  [TextToSpeechVoice.Libby]: 'female',
  [TextToSpeechVoice.Alfie]: 'male',
  [TextToSpeechVoice.Sam]: 'male',
  [TextToSpeechVoice.Yan]: 'female',
  [TextToSpeechVoice.Connor]: 'male',
  [TextToSpeechVoice.Emily]: 'female',
  [TextToSpeechVoice.Neerja]: 'female',
  [TextToSpeechVoice.Prabhat]: 'male',
  [TextToSpeechVoice.Asilia]: 'female',
  [TextToSpeechVoice.Chilemba]: 'male',
  [TextToSpeechVoice.Abeo]: 'male',
  [TextToSpeechVoice.Ezinne]: 'female',
  [TextToSpeechVoice.Mitchell]: 'male',
  [TextToSpeechVoice.Molly]: 'female',
  [TextToSpeechVoice.James]: 'male',
  [TextToSpeechVoice.Rosa]: 'female',
  [TextToSpeechVoice.Luna]: 'female',
  [TextToSpeechVoice.Wayne]: 'male',
  [TextToSpeechVoice.Elimu]: 'male',
  [TextToSpeechVoice.Imani]: 'female',
  [TextToSpeechVoice.Jenny]: 'female',
  [TextToSpeechVoice.Leah]: 'female',
  [TextToSpeechVoice.Luke]: 'male',
  [TextToSpeechVoice.Elena]: 'female',
  [TextToSpeechVoice.Tomas]: 'male',
  [TextToSpeechVoice.Marcelo]: 'male',
  [TextToSpeechVoice.Sofia]: 'female',
  [TextToSpeechVoice.Catalina]: 'female',
  [TextToSpeechVoice.Lorenzo]: 'male',
  [TextToSpeechVoice.Gonzalo]: 'male',
  [TextToSpeechVoice.Salome]: 'female',
  [TextToSpeechVoice.Juan]: 'male',
  [TextToSpeechVoice.Maria]: 'female',
  [TextToSpeechVoice.Belkys]: 'female',
  [TextToSpeechVoice.Manuel]: 'male',
  [TextToSpeechVoice.Emilio]: 'male',
  [TextToSpeechVoice.Ramona]: 'female',
  [TextToSpeechVoice.Andrea]: 'female',
  [TextToSpeechVoice.Luis]: 'male',
  [TextToSpeechVoice.Abril]: 'female',
  [TextToSpeechVoice.Alvaro]: 'male',
  [TextToSpeechVoice.Javier]: 'male',
  [TextToSpeechVoice.Teresa]: 'female',
  [TextToSpeechVoice.Andres]: 'male',
  [TextToSpeechVoice.Marta]: 'female',
  [TextToSpeechVoice.Carlos]: 'male',
  [TextToSpeechVoice.Karla]: 'female',
  [TextToSpeechVoice.Beatriz]: 'female',
  [TextToSpeechVoice.Cecilio]: 'male',
  [TextToSpeechVoice.Federico]: 'male',
  [TextToSpeechVoice.Yolanda]: 'female',
  [TextToSpeechVoice.Margarita]: 'female',
  [TextToSpeechVoice.Roberto]: 'male',
  [TextToSpeechVoice.Alex]: 'male',
  [TextToSpeechVoice.Camila]: 'female',
  [TextToSpeechVoice.Karina]: 'female',
  [TextToSpeechVoice.Victor]: 'male',
  [TextToSpeechVoice.Mario]: 'male',
  [TextToSpeechVoice.Tania]: 'female',
  [TextToSpeechVoice.Lorena]: 'female',
  [TextToSpeechVoice.Rodrigo]: 'male',
  [TextToSpeechVoice.Alonso]: 'male',
  [TextToSpeechVoice.Paloma]: 'female',
  [TextToSpeechVoice.Mateo]: 'male',
  [TextToSpeechVoice.Valentina]: 'female',
  [TextToSpeechVoice.Paola]: 'female',
  [TextToSpeechVoice.Sebastian]: 'male',
  [TextToSpeechVoice.Anu]: 'female',
  [TextToSpeechVoice.Kert]: 'male',
  [TextToSpeechVoice.Ainhoa]: 'female',
  [TextToSpeechVoice.Ander]: 'male',
  [TextToSpeechVoice.Dilara]: 'female',
  [TextToSpeechVoice.Farid]: 'male',
  [TextToSpeechVoice.Selma]: 'female',
  [TextToSpeechVoice.Harri]: 'male',
  [TextToSpeechVoice.Angelo]: 'male',
  [TextToSpeechVoice.Blessica]: 'female',
  [TextToSpeechVoice.Charline]: 'female',
  [TextToSpeechVoice.Gerard]: 'male',
  [TextToSpeechVoice.Sylvie]: 'female',
  [TextToSpeechVoice.Antoine]: 'male',
  [TextToSpeechVoice.Ariane]: 'female',
  [TextToSpeechVoice.Fabrice]: 'male',
  [TextToSpeechVoice.Alain]: 'male',
  [TextToSpeechVoice.Brigitte]: 'female',
  [TextToSpeechVoice.Colm]: 'male',
  [TextToSpeechVoice.Orla]: 'female',
  [TextToSpeechVoice.Roi]: 'male',
  [TextToSpeechVoice.Sabela]: 'female',
  [TextToSpeechVoice.Dhwani]: 'female',
  [TextToSpeechVoice.Niranjan]: 'male',
  [TextToSpeechVoice.Avri]: 'male',
  [TextToSpeechVoice.Hila]: 'female',
  [TextToSpeechVoice.Madhur]: 'male',
  [TextToSpeechVoice.Swara]: 'female',
  [TextToSpeechVoice.Gabrijela]: 'female',
  [TextToSpeechVoice.Srecko]: 'male',
  [TextToSpeechVoice.Noemi]: 'female',
  [TextToSpeechVoice.Tamas]: 'male',
  [TextToSpeechVoice.Anahit]: 'female',
  [TextToSpeechVoice.Hayk]: 'male',
  [TextToSpeechVoice.Ardi]: 'male',
  [TextToSpeechVoice.Gadis]: 'female',
  [TextToSpeechVoice.Gudrun]: 'female',
  [TextToSpeechVoice.Gunnar]: 'male',
  [TextToSpeechVoice.Isabella]: 'female',
  [TextToSpeechVoice.Benigno]: 'male',
  [TextToSpeechVoice.Nanami]: 'female',
  [TextToSpeechVoice.Daichi]: 'male',
  [TextToSpeechVoice.Dimas]: 'male',
  [TextToSpeechVoice.Siti]: 'female',
  [TextToSpeechVoice.Eka]: 'female',
  [TextToSpeechVoice.Giorgi]: 'male',
  [TextToSpeechVoice.Aigul]: 'female',
  [TextToSpeechVoice.Daulet]: 'male',
  [TextToSpeechVoice.Piseth]: 'male',
  [TextToSpeechVoice.Sreymom]: 'female',
  [TextToSpeechVoice.Gagan]: 'male',
  [TextToSpeechVoice.Sapna]: 'female',
  [TextToSpeechVoice.SunHi]: 'female',
  [TextToSpeechVoice.InJoon]: 'male',
  [TextToSpeechVoice.Chanthavong]: 'male',
  [TextToSpeechVoice.Keomany]: 'female',
  [TextToSpeechVoice.Leonas]: 'male',
  [TextToSpeechVoice.Ona]: 'female',
  [TextToSpeechVoice.Everita]: 'female',
  [TextToSpeechVoice.Nils]: 'male',
  [TextToSpeechVoice.Aleksandar]: 'male',
  [TextToSpeechVoice.Marija]: 'female',
  [TextToSpeechVoice.Midhun]: 'male',
  [TextToSpeechVoice.Sobhana]: 'female',
  [TextToSpeechVoice.Bataa]: 'male',
  [TextToSpeechVoice.Yesui]: 'female',
  [TextToSpeechVoice.Aarohi]: 'female',
  [TextToSpeechVoice.Manohar]: 'male',
  [TextToSpeechVoice.Osman]: 'male',
  [TextToSpeechVoice.Yasmin]: 'female',
  [TextToSpeechVoice.Grace]: 'female',
  [TextToSpeechVoice.Joseph]: 'male',
  [TextToSpeechVoice.Nilar]: 'female',
  [TextToSpeechVoice.Thiha]: 'male',
  [TextToSpeechVoice.Pernille]: 'female',
  [TextToSpeechVoice.Finn]: 'male',
  [TextToSpeechVoice.Hemkala]: 'female',
  [TextToSpeechVoice.Sagar]: 'male',
  [TextToSpeechVoice.Arnaud]: 'male',
  [TextToSpeechVoice.Dena]: 'female',
  [TextToSpeechVoice.Colette]: 'female',
  [TextToSpeechVoice.Maarten]: 'male',
  [TextToSpeechVoice.Agnieszka]: 'female',
  [TextToSpeechVoice.Marek]: 'male',
  [TextToSpeechVoice.GulNawaz]: 'male',
  [TextToSpeechVoice.Latifa]: 'female',
  [TextToSpeechVoice.Francisca]: 'female',
  [TextToSpeechVoice.Donato]: 'male',
  [TextToSpeechVoice.Duarte]: 'male',
  [TextToSpeechVoice.Fernanda]: 'female',
  [TextToSpeechVoice.Alina]: 'female',
  [TextToSpeechVoice.Emil]: 'male',
  [TextToSpeechVoice.Svetlana]: 'female',
  [TextToSpeechVoice.Dmitry]: 'male',
  [TextToSpeechVoice.Sameera]: 'male',
  [TextToSpeechVoice.Thilini]: 'female',
  [TextToSpeechVoice.Lukas]: 'male',
  [TextToSpeechVoice.Viktoria]: 'female',
  [TextToSpeechVoice.Petra]: 'female',
  [TextToSpeechVoice.Rok]: 'male',
  [TextToSpeechVoice.Muuse]: 'male',
  [TextToSpeechVoice.Ubax]: 'female',
  [TextToSpeechVoice.Anila]: 'female',
  [TextToSpeechVoice.Ilir]: 'male',
  [TextToSpeechVoice.Nicholas]: 'male',
  [TextToSpeechVoice.Sophie]: 'female',
  [TextToSpeechVoice.Jajang]: 'male',
  [TextToSpeechVoice.Tuti]: 'female',
  [TextToSpeechVoice.Sofie]: 'female',
  [TextToSpeechVoice.Mattias]: 'male',
  [TextToSpeechVoice.Rafiki]: 'male',
  [TextToSpeechVoice.Zuri]: 'female',
  [TextToSpeechVoice.Daudi]: 'male',
  [TextToSpeechVoice.Rehema]: 'female',
  [TextToSpeechVoice.Pallavi]: 'female',
  [TextToSpeechVoice.Valluvar]: 'male',
  [TextToSpeechVoice.Kumar]: 'male',
  [TextToSpeechVoice.Saranya]: 'female',
  [TextToSpeechVoice.Kani]: 'female',
  [TextToSpeechVoice.Surya]: 'male',
  [TextToSpeechVoice.Anbu]: 'male',
  [TextToSpeechVoice.Venba]: 'female',
  [TextToSpeechVoice.Mohan]: 'male',
  [TextToSpeechVoice.Shruti]: 'female',
  [TextToSpeechVoice.Premwadee]: 'female',
  [TextToSpeechVoice.Niwat]: 'male',
  [TextToSpeechVoice.Ahmet]: 'male',
  [TextToSpeechVoice.Emel]: 'female',
  [TextToSpeechVoice.Ostap]: 'male',
  [TextToSpeechVoice.Polina]: 'female',
  [TextToSpeechVoice.Gul]: 'female',
  [TextToSpeechVoice.Salman]: 'male',
  [TextToSpeechVoice.Asad]: 'male',
  [TextToSpeechVoice.Uzma]: 'female',
  [TextToSpeechVoice.Madina]: 'female',
  [TextToSpeechVoice.Sardor]: 'male',
  [TextToSpeechVoice.HoaiMy]: 'female',
  [TextToSpeechVoice.NamMinh]: 'male',
  [TextToSpeechVoice.Xiaotong]: 'female',
  [TextToSpeechVoice.Yunzhe]: 'male',
  [TextToSpeechVoice.XiaoMin]: 'female',
  [TextToSpeechVoice.YunSong]: 'male',
  [TextToSpeechVoice.Xiaoxiao]: 'female',
  [TextToSpeechVoice.Yunyang]: 'male',
  [TextToSpeechVoice.Yundeng]: 'male',
  [TextToSpeechVoice.Xiaobei]: 'female',
  [TextToSpeechVoice.Xiaoni]: 'female',
  [TextToSpeechVoice.Yunxiang]: 'male',
  [TextToSpeechVoice.Yunxi]: 'male',
  [TextToSpeechVoice.HiuMaan]: 'female',
  [TextToSpeechVoice.WanLung]: 'male',
  [TextToSpeechVoice.HsiaoChen]: 'female',
  [TextToSpeechVoice.YunJhe]: 'male',
  [TextToSpeechVoice.Thando]: 'female',
  [TextToSpeechVoice.Themba]: 'male',
};

export type TextToSpeechVersion = 'v3' | 'v2';
export const TextToSpeechVoiceToApiVersion: { [voice in TextToSpeechVoice]: TextToSpeechVersion } = {
  [TextToSpeechVoice.UnrealDan]: 'v3',
  [TextToSpeechVoice.UnrealLiv]: 'v3',
  [TextToSpeechVoice.UnrealDanV7]: 'v3',
  [TextToSpeechVoice.UnrealLivV7]: 'v3',
  [TextToSpeechVoice.UnrealAmy]: 'v3',
  [TextToSpeechVoice.UnrealWill]: 'v3',
  [TextToSpeechVoice.UnrealZoe]: 'v3',
  [TextToSpeechVoice.KokoroDefault]: 'v3',
  [TextToSpeechVoice.KokoroBella]: 'v3',
  [TextToSpeechVoice.KokoroSarah]: 'v3',
  [TextToSpeechVoice.KokoroAdam]: 'v3',
  [TextToSpeechVoice.KokoroMichael]: 'v3',
  [TextToSpeechVoice.KokoroEmma]: 'v3',
  [TextToSpeechVoice.KokoroIsabella]: 'v3',
  [TextToSpeechVoice.KokoroGeorge]: 'v3',
  [TextToSpeechVoice.KokoroLewis]: 'v3',
  [TextToSpeechVoice.KokoroNicole]: 'v3',
  [TextToSpeechVoice.KokoroSky]: 'v3',

  [TextToSpeechVoice.Guy]: 'v2',
  [TextToSpeechVoice.Aria]: 'v2',

  [TextToSpeechVoice.Adri]: 'v2',
  [TextToSpeechVoice.Willem]: 'v2',
  [TextToSpeechVoice.Ameha]: 'v2',
  [TextToSpeechVoice.Mekdes]: 'v2',
  [TextToSpeechVoice.Fatima]: 'v2',
  [TextToSpeechVoice.Hamdan]: 'v2',
  [TextToSpeechVoice.Ali]: 'v2',
  [TextToSpeechVoice.Laila]: 'v2',
  [TextToSpeechVoice.Amina]: 'v2',
  [TextToSpeechVoice.Ismael]: 'v2',
  [TextToSpeechVoice.Salma]: 'v2',
  [TextToSpeechVoice.Shakir]: 'v2',
  [TextToSpeechVoice.Bassel]: 'v2',
  [TextToSpeechVoice.Rana]: 'v2',
  [TextToSpeechVoice.Sana]: 'v2',
  [TextToSpeechVoice.Taim]: 'v2',
  [TextToSpeechVoice.Fahed]: 'v2',
  [TextToSpeechVoice.Noura]: 'v2',
  [TextToSpeechVoice.Layla]: 'v2',
  [TextToSpeechVoice.Rami]: 'v2',
  [TextToSpeechVoice.Iman]: 'v2',
  [TextToSpeechVoice.Omar]: 'v2',
  [TextToSpeechVoice.Jamal]: 'v2',
  [TextToSpeechVoice.Mouna]: 'v2',
  [TextToSpeechVoice.Abdullah]: 'v2',
  [TextToSpeechVoice.Aysha]: 'v2',
  [TextToSpeechVoice.Amal]: 'v2',
  [TextToSpeechVoice.Moaz]: 'v2',
  [TextToSpeechVoice.Hamed]: 'v2',
  [TextToSpeechVoice.Zariyah]: 'v2',
  [TextToSpeechVoice.Amany]: 'v2',
  [TextToSpeechVoice.Laith]: 'v2',
  [TextToSpeechVoice.Hedi]: 'v2',
  [TextToSpeechVoice.Reem]: 'v2',
  [TextToSpeechVoice.Maryam]: 'v2',
  [TextToSpeechVoice.Saleh]: 'v2',
  [TextToSpeechVoice.Babek]: 'v2',
  [TextToSpeechVoice.Banu]: 'v2',
  [TextToSpeechVoice.Borislav]: 'v2',
  [TextToSpeechVoice.Kalina]: 'v2',
  [TextToSpeechVoice.Nabanita]: 'v2',
  [TextToSpeechVoice.Pradeep]: 'v2',
  [TextToSpeechVoice.Bashkar]: 'v2',
  [TextToSpeechVoice.Tanishaa]: 'v2',
  [TextToSpeechVoice.Goran]: 'v2',
  [TextToSpeechVoice.Vesna]: 'v2',
  [TextToSpeechVoice.Joana]: 'v2',
  [TextToSpeechVoice.Enric]: 'v2',
  [TextToSpeechVoice.Antonin]: 'v2',
  [TextToSpeechVoice.Vlasta]: 'v2',
  [TextToSpeechVoice.Aled]: 'v2',
  [TextToSpeechVoice.Nia]: 'v2',
  [TextToSpeechVoice.Christel]: 'v2',
  [TextToSpeechVoice.Jeppe]: 'v2',
  [TextToSpeechVoice.Ingrid]: 'v2',
  [TextToSpeechVoice.Jonas]: 'v2',
  [TextToSpeechVoice.Jan]: 'v2',
  [TextToSpeechVoice.Leni]: 'v2',
  [TextToSpeechVoice.Katja]: 'v2',
  [TextToSpeechVoice.Killian]: 'v2',
  [TextToSpeechVoice.Athina]: 'v2',
  [TextToSpeechVoice.Nestoras]: 'v2',
  [TextToSpeechVoice.Annette]: 'v2',
  [TextToSpeechVoice.Darren]: 'v2',
  [TextToSpeechVoice.Clara]: 'v2',
  [TextToSpeechVoice.Liam]: 'v2',
  [TextToSpeechVoice.Libby]: 'v2',
  [TextToSpeechVoice.Alfie]: 'v2',
  [TextToSpeechVoice.Sam]: 'v2',
  [TextToSpeechVoice.Yan]: 'v2',
  [TextToSpeechVoice.Connor]: 'v2',
  [TextToSpeechVoice.Emily]: 'v2',
  [TextToSpeechVoice.Neerja]: 'v2',
  [TextToSpeechVoice.Prabhat]: 'v2',
  [TextToSpeechVoice.Asilia]: 'v2',
  [TextToSpeechVoice.Chilemba]: 'v2',
  [TextToSpeechVoice.Abeo]: 'v2',
  [TextToSpeechVoice.Ezinne]: 'v2',
  [TextToSpeechVoice.Mitchell]: 'v2',
  [TextToSpeechVoice.Molly]: 'v2',
  [TextToSpeechVoice.James]: 'v2',
  [TextToSpeechVoice.Rosa]: 'v2',
  [TextToSpeechVoice.Luna]: 'v2',
  [TextToSpeechVoice.Wayne]: 'v2',
  [TextToSpeechVoice.Elimu]: 'v2',
  [TextToSpeechVoice.Imani]: 'v2',
  [TextToSpeechVoice.Jenny]: 'v2',
  [TextToSpeechVoice.Leah]: 'v2',
  [TextToSpeechVoice.Luke]: 'v2',
  [TextToSpeechVoice.Elena]: 'v2',
  [TextToSpeechVoice.Tomas]: 'v2',
  [TextToSpeechVoice.Marcelo]: 'v2',
  [TextToSpeechVoice.Sofia]: 'v2',
  [TextToSpeechVoice.Catalina]: 'v2',
  [TextToSpeechVoice.Lorenzo]: 'v2',
  [TextToSpeechVoice.Gonzalo]: 'v2',
  [TextToSpeechVoice.Salome]: 'v2',
  [TextToSpeechVoice.Juan]: 'v2',
  [TextToSpeechVoice.Maria]: 'v2',
  [TextToSpeechVoice.Belkys]: 'v2',
  [TextToSpeechVoice.Manuel]: 'v2',
  [TextToSpeechVoice.Emilio]: 'v2',
  [TextToSpeechVoice.Ramona]: 'v2',
  [TextToSpeechVoice.Andrea]: 'v2',
  [TextToSpeechVoice.Luis]: 'v2',
  [TextToSpeechVoice.Abril]: 'v2',
  [TextToSpeechVoice.Alvaro]: 'v2',
  [TextToSpeechVoice.Javier]: 'v2',
  [TextToSpeechVoice.Teresa]: 'v2',
  [TextToSpeechVoice.Andres]: 'v2',
  [TextToSpeechVoice.Marta]: 'v2',
  [TextToSpeechVoice.Carlos]: 'v2',
  [TextToSpeechVoice.Karla]: 'v2',
  [TextToSpeechVoice.Beatriz]: 'v2',
  [TextToSpeechVoice.Cecilio]: 'v2',
  [TextToSpeechVoice.Federico]: 'v2',
  [TextToSpeechVoice.Yolanda]: 'v2',
  [TextToSpeechVoice.Margarita]: 'v2',
  [TextToSpeechVoice.Roberto]: 'v2',
  [TextToSpeechVoice.Alex]: 'v2',
  [TextToSpeechVoice.Camila]: 'v2',
  [TextToSpeechVoice.Karina]: 'v2',
  [TextToSpeechVoice.Victor]: 'v2',
  [TextToSpeechVoice.Mario]: 'v2',
  [TextToSpeechVoice.Tania]: 'v2',
  [TextToSpeechVoice.Lorena]: 'v2',
  [TextToSpeechVoice.Rodrigo]: 'v2',
  [TextToSpeechVoice.Alonso]: 'v2',
  [TextToSpeechVoice.Paloma]: 'v2',
  [TextToSpeechVoice.Mateo]: 'v2',
  [TextToSpeechVoice.Valentina]: 'v2',
  [TextToSpeechVoice.Paola]: 'v2',
  [TextToSpeechVoice.Sebastian]: 'v2',
  [TextToSpeechVoice.Anu]: 'v2',
  [TextToSpeechVoice.Kert]: 'v2',
  [TextToSpeechVoice.Ainhoa]: 'v2',
  [TextToSpeechVoice.Ander]: 'v2',
  [TextToSpeechVoice.Dilara]: 'v2',
  [TextToSpeechVoice.Farid]: 'v2',
  [TextToSpeechVoice.Selma]: 'v2',
  [TextToSpeechVoice.Harri]: 'v2',
  [TextToSpeechVoice.Angelo]: 'v2',
  [TextToSpeechVoice.Blessica]: 'v2',
  [TextToSpeechVoice.Charline]: 'v2',
  [TextToSpeechVoice.Gerard]: 'v2',
  [TextToSpeechVoice.Sylvie]: 'v2',
  [TextToSpeechVoice.Antoine]: 'v2',
  [TextToSpeechVoice.Ariane]: 'v2',
  [TextToSpeechVoice.Fabrice]: 'v2',
  [TextToSpeechVoice.Alain]: 'v2',
  [TextToSpeechVoice.Brigitte]: 'v2',
  [TextToSpeechVoice.Colm]: 'v2',
  [TextToSpeechVoice.Orla]: 'v2',
  [TextToSpeechVoice.Roi]: 'v2',
  [TextToSpeechVoice.Sabela]: 'v2',
  [TextToSpeechVoice.Dhwani]: 'v2',
  [TextToSpeechVoice.Niranjan]: 'v2',
  [TextToSpeechVoice.Avri]: 'v2',
  [TextToSpeechVoice.Hila]: 'v2',
  [TextToSpeechVoice.Madhur]: 'v2',
  [TextToSpeechVoice.Swara]: 'v2',
  [TextToSpeechVoice.Gabrijela]: 'v2',
  [TextToSpeechVoice.Srecko]: 'v2',
  [TextToSpeechVoice.Noemi]: 'v2',
  [TextToSpeechVoice.Tamas]: 'v2',
  [TextToSpeechVoice.Anahit]: 'v2',
  [TextToSpeechVoice.Hayk]: 'v2',
  [TextToSpeechVoice.Ardi]: 'v2',
  [TextToSpeechVoice.Gadis]: 'v2',
  [TextToSpeechVoice.Gudrun]: 'v2',
  [TextToSpeechVoice.Gunnar]: 'v2',
  [TextToSpeechVoice.Isabella]: 'v2',
  [TextToSpeechVoice.Benigno]: 'v2',
  [TextToSpeechVoice.Nanami]: 'v2',
  [TextToSpeechVoice.Daichi]: 'v2',
  [TextToSpeechVoice.Dimas]: 'v2',
  [TextToSpeechVoice.Siti]: 'v2',
  [TextToSpeechVoice.Eka]: 'v2',
  [TextToSpeechVoice.Giorgi]: 'v2',
  [TextToSpeechVoice.Aigul]: 'v2',
  [TextToSpeechVoice.Daulet]: 'v2',
  [TextToSpeechVoice.Piseth]: 'v2',
  [TextToSpeechVoice.Sreymom]: 'v2',
  [TextToSpeechVoice.Gagan]: 'v2',
  [TextToSpeechVoice.Sapna]: 'v2',
  [TextToSpeechVoice.SunHi]: 'v2',
  [TextToSpeechVoice.InJoon]: 'v2',
  [TextToSpeechVoice.Chanthavong]: 'v2',
  [TextToSpeechVoice.Keomany]: 'v2',
  [TextToSpeechVoice.Leonas]: 'v2',
  [TextToSpeechVoice.Ona]: 'v2',
  [TextToSpeechVoice.Everita]: 'v2',
  [TextToSpeechVoice.Nils]: 'v2',
  [TextToSpeechVoice.Aleksandar]: 'v2',
  [TextToSpeechVoice.Marija]: 'v2',
  [TextToSpeechVoice.Midhun]: 'v2',
  [TextToSpeechVoice.Sobhana]: 'v2',
  [TextToSpeechVoice.Bataa]: 'v2',
  [TextToSpeechVoice.Yesui]: 'v2',
  [TextToSpeechVoice.Aarohi]: 'v2',
  [TextToSpeechVoice.Manohar]: 'v2',
  [TextToSpeechVoice.Osman]: 'v2',
  [TextToSpeechVoice.Yasmin]: 'v2',
  [TextToSpeechVoice.Grace]: 'v2',
  [TextToSpeechVoice.Joseph]: 'v2',
  [TextToSpeechVoice.Nilar]: 'v2',
  [TextToSpeechVoice.Thiha]: 'v2',
  [TextToSpeechVoice.Pernille]: 'v2',
  [TextToSpeechVoice.Finn]: 'v2',
  [TextToSpeechVoice.Hemkala]: 'v2',
  [TextToSpeechVoice.Sagar]: 'v2',
  [TextToSpeechVoice.Arnaud]: 'v2',
  [TextToSpeechVoice.Dena]: 'v2',
  [TextToSpeechVoice.Colette]: 'v2',
  [TextToSpeechVoice.Maarten]: 'v2',
  [TextToSpeechVoice.Agnieszka]: 'v2',
  [TextToSpeechVoice.Marek]: 'v2',
  [TextToSpeechVoice.GulNawaz]: 'v2',
  [TextToSpeechVoice.Latifa]: 'v2',
  [TextToSpeechVoice.Francisca]: 'v2',
  [TextToSpeechVoice.Donato]: 'v2',
  [TextToSpeechVoice.Duarte]: 'v2',
  [TextToSpeechVoice.Fernanda]: 'v2',
  [TextToSpeechVoice.Alina]: 'v2',
  [TextToSpeechVoice.Emil]: 'v2',
  [TextToSpeechVoice.Svetlana]: 'v2',
  [TextToSpeechVoice.Dmitry]: 'v2',
  [TextToSpeechVoice.Sameera]: 'v2',
  [TextToSpeechVoice.Thilini]: 'v2',
  [TextToSpeechVoice.Lukas]: 'v2',
  [TextToSpeechVoice.Viktoria]: 'v2',
  [TextToSpeechVoice.Petra]: 'v2',
  [TextToSpeechVoice.Rok]: 'v2',
  [TextToSpeechVoice.Muuse]: 'v2',
  [TextToSpeechVoice.Ubax]: 'v2',
  [TextToSpeechVoice.Anila]: 'v2',
  [TextToSpeechVoice.Ilir]: 'v2',
  [TextToSpeechVoice.Nicholas]: 'v2',
  [TextToSpeechVoice.Sophie]: 'v2',
  [TextToSpeechVoice.Jajang]: 'v2',
  [TextToSpeechVoice.Tuti]: 'v2',
  [TextToSpeechVoice.Sofie]: 'v2',
  [TextToSpeechVoice.Mattias]: 'v2',
  [TextToSpeechVoice.Rafiki]: 'v2',
  [TextToSpeechVoice.Zuri]: 'v2',
  [TextToSpeechVoice.Daudi]: 'v2',
  [TextToSpeechVoice.Rehema]: 'v2',
  [TextToSpeechVoice.Pallavi]: 'v2',
  [TextToSpeechVoice.Valluvar]: 'v2',
  [TextToSpeechVoice.Kumar]: 'v2',
  [TextToSpeechVoice.Saranya]: 'v2',
  [TextToSpeechVoice.Kani]: 'v2',
  [TextToSpeechVoice.Surya]: 'v2',
  [TextToSpeechVoice.Anbu]: 'v2',
  [TextToSpeechVoice.Venba]: 'v2',
  [TextToSpeechVoice.Mohan]: 'v2',
  [TextToSpeechVoice.Shruti]: 'v2',
  [TextToSpeechVoice.Premwadee]: 'v2',
  [TextToSpeechVoice.Niwat]: 'v2',
  [TextToSpeechVoice.Ahmet]: 'v2',
  [TextToSpeechVoice.Emel]: 'v2',
  [TextToSpeechVoice.Ostap]: 'v2',
  [TextToSpeechVoice.Polina]: 'v2',
  [TextToSpeechVoice.Gul]: 'v2',
  [TextToSpeechVoice.Salman]: 'v2',
  [TextToSpeechVoice.Asad]: 'v2',
  [TextToSpeechVoice.Uzma]: 'v2',
  [TextToSpeechVoice.Madina]: 'v2',
  [TextToSpeechVoice.Sardor]: 'v2',
  [TextToSpeechVoice.HoaiMy]: 'v2',
  [TextToSpeechVoice.NamMinh]: 'v2',
  [TextToSpeechVoice.Xiaotong]: 'v2',
  [TextToSpeechVoice.Yunzhe]: 'v2',
  [TextToSpeechVoice.XiaoMin]: 'v2',
  [TextToSpeechVoice.YunSong]: 'v2',
  [TextToSpeechVoice.Xiaoxiao]: 'v2',
  [TextToSpeechVoice.Yunyang]: 'v2',
  [TextToSpeechVoice.Yundeng]: 'v2',
  [TextToSpeechVoice.Xiaobei]: 'v2',
  [TextToSpeechVoice.Xiaoni]: 'v2',
  [TextToSpeechVoice.Yunxiang]: 'v2',
  [TextToSpeechVoice.Yunxi]: 'v2',
  [TextToSpeechVoice.HiuMaan]: 'v2',
  [TextToSpeechVoice.WanLung]: 'v2',
  [TextToSpeechVoice.HsiaoChen]: 'v2',
  [TextToSpeechVoice.YunJhe]: 'v2',
  [TextToSpeechVoice.Thando]: 'v2',
  [TextToSpeechVoice.Themba]: 'v2',
};

export type TTSLanguage =
  | 'af'
  | 'am'
  | 'ar'
  | 'az'
  | 'bg'
  | 'bn'
  | 'bs'
  | 'ca'
  | 'cs'
  | 'cy'
  | 'da'
  | 'de'
  | 'el'
  | 'en'
  | 'es'
  | 'et'
  | 'eu'
  | 'fa'
  | 'fi'
  | 'fil'
  | 'fr'
  | 'ga'
  | 'gl'
  | 'gu'
  | 'he'
  | 'hi'
  | 'hr'
  | 'hu'
  | 'hy'
  | 'id'
  | 'is'
  | 'it'
  | 'ja'
  | 'jv'
  | 'ka'
  | 'kk'
  | 'km'
  | 'kn'
  | 'ko'
  | 'lo'
  | 'lt'
  | 'lv'
  | 'mk'
  | 'ml'
  | 'mn'
  | 'mr'
  | 'ms'
  | 'mt'
  | 'my'
  | 'nb'
  | 'ne'
  | 'nl'
  | 'nl_be'
  | 'pl'
  | 'ps'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'si'
  | 'sk'
  | 'sl'
  | 'so'
  | 'sq'
  | 'sr'
  | 'su'
  | 'sv'
  | 'sw'
  | 'ta'
  | 'te'
  | 'th'
  | 'tr'
  | 'uk'
  | 'ur'
  | 'uz'
  | 'vi'
  | 'wuu'
  | 'yue'
  | 'zh'
  | 'zu';
export const TextToSpeechVoicesByLanguage: { [lang in TTSLanguage]: TextToSpeechVoice[] } = {
  af: [TextToSpeechVoice.Willem, TextToSpeechVoice.Adri],
  am: [TextToSpeechVoice.Ameha, TextToSpeechVoice.Mekdes],
  ar: [TextToSpeechVoice.Shakir, TextToSpeechVoice.Salma],
  az: [TextToSpeechVoice.Babek, TextToSpeechVoice.Banu],
  bg: [TextToSpeechVoice.Borislav, TextToSpeechVoice.Kalina],
  bn: [TextToSpeechVoice.Bashkar, TextToSpeechVoice.Tanishaa],
  bs: [TextToSpeechVoice.Goran, TextToSpeechVoice.Vesna],
  ca: [TextToSpeechVoice.Enric, TextToSpeechVoice.Joana],
  cs: [TextToSpeechVoice.Antonin, TextToSpeechVoice.Vlasta],
  cy: [TextToSpeechVoice.Aled, TextToSpeechVoice.Nia],
  da: [TextToSpeechVoice.Jeppe, TextToSpeechVoice.Christel],
  de: [TextToSpeechVoice.Killian, TextToSpeechVoice.Katja],
  el: [TextToSpeechVoice.Nestoras, TextToSpeechVoice.Athina],
  en: [
    TextToSpeechVoice.KokoroMichael,
    TextToSpeechVoice.KokoroSky,
    TextToSpeechVoice.KokoroGeorge, // british voice
    // TextToSpeechVoice.KokoroLewis, // british voice
    TextToSpeechVoice.KokoroEmma, // british voice
    // TextToSpeechVoice.KokoroIsabella, // british voice
    // TextToSpeechVoice.KokoroNicole, // very strange whisper
    // TextToSpeechVoice.KokoroDefault,
    // TextToSpeechVoice.KokoroAdam,
    TextToSpeechVoice.UnrealDanV7,
    TextToSpeechVoice.UnrealLivV7,
    TextToSpeechVoice.UnrealWill,
    TextToSpeechVoice.UnrealAmy,
    TextToSpeechVoice.UnrealZoe,
    TextToSpeechVoice.UnrealDan,
    TextToSpeechVoice.UnrealLiv,
    TextToSpeechVoice.Guy,
    TextToSpeechVoice.Aria,
  ],
  es: [TextToSpeechVoice.Cecilio, TextToSpeechVoice.Beatriz],
  et: [TextToSpeechVoice.Kert, TextToSpeechVoice.Anu],
  eu: [TextToSpeechVoice.Ander, TextToSpeechVoice.Ainhoa],
  fa: [TextToSpeechVoice.Farid, TextToSpeechVoice.Dilara],
  fi: [TextToSpeechVoice.Harri, TextToSpeechVoice.Selma],
  fil: [TextToSpeechVoice.Angelo, TextToSpeechVoice.Blessica],
  fr: [TextToSpeechVoice.Alain, TextToSpeechVoice.Brigitte],
  ga: [TextToSpeechVoice.Colm, TextToSpeechVoice.Orla],
  gl: [TextToSpeechVoice.Roi, TextToSpeechVoice.Sabela],
  gu: [TextToSpeechVoice.Niranjan, TextToSpeechVoice.Dhwani],
  he: [TextToSpeechVoice.Avri, TextToSpeechVoice.Hila],
  hi: [TextToSpeechVoice.Madhur, TextToSpeechVoice.Swara],
  hr: [TextToSpeechVoice.Srecko, TextToSpeechVoice.Gabrijela],
  hu: [TextToSpeechVoice.Tamas, TextToSpeechVoice.Noemi],
  hy: [TextToSpeechVoice.Hayk, TextToSpeechVoice.Anahit],
  id: [TextToSpeechVoice.Ardi, TextToSpeechVoice.Gadis],
  is: [TextToSpeechVoice.Gunnar, TextToSpeechVoice.Gudrun],
  it: [TextToSpeechVoice.Benigno, TextToSpeechVoice.Isabella],
  ja: [TextToSpeechVoice.Daichi, TextToSpeechVoice.Nanami],
  jv: [TextToSpeechVoice.Dimas, TextToSpeechVoice.Siti],
  ka: [TextToSpeechVoice.Giorgi, TextToSpeechVoice.Eka],
  kk: [TextToSpeechVoice.Daulet, TextToSpeechVoice.Aigul],
  km: [TextToSpeechVoice.Piseth, TextToSpeechVoice.Sreymom],
  kn: [TextToSpeechVoice.Gagan, TextToSpeechVoice.Sapna],
  ko: [TextToSpeechVoice.InJoon, TextToSpeechVoice.SunHi],
  lo: [TextToSpeechVoice.Chanthavong, TextToSpeechVoice.Keomany],
  lt: [TextToSpeechVoice.Leonas, TextToSpeechVoice.Ona],
  lv: [TextToSpeechVoice.Nils, TextToSpeechVoice.Everita],
  mk: [TextToSpeechVoice.Aleksandar, TextToSpeechVoice.Marija],
  ml: [TextToSpeechVoice.Midhun, TextToSpeechVoice.Sobhana],
  mn: [TextToSpeechVoice.Bataa, TextToSpeechVoice.Yesui],
  mr: [TextToSpeechVoice.Manohar, TextToSpeechVoice.Aarohi],
  ms: [TextToSpeechVoice.Osman, TextToSpeechVoice.Yasmin],
  mt: [TextToSpeechVoice.Joseph, TextToSpeechVoice.Grace],
  my: [TextToSpeechVoice.Thiha, TextToSpeechVoice.Nilar],
  nb: [TextToSpeechVoice.Finn, TextToSpeechVoice.Pernille],
  ne: [TextToSpeechVoice.Sagar, TextToSpeechVoice.Hemkala],
  nl: [TextToSpeechVoice.Maarten, TextToSpeechVoice.Colette],
  nl_be: [TextToSpeechVoice.Dena, TextToSpeechVoice.Arnaud],
  pl: [TextToSpeechVoice.Marek, TextToSpeechVoice.Agnieszka],
  ps: [TextToSpeechVoice.GulNawaz, TextToSpeechVoice.Latifa],
  pt: [TextToSpeechVoice.Donato, TextToSpeechVoice.Francisca],
  ro: [TextToSpeechVoice.Emil, TextToSpeechVoice.Alina],
  ru: [TextToSpeechVoice.Dmitry, TextToSpeechVoice.Svetlana],
  si: [TextToSpeechVoice.Sameera, TextToSpeechVoice.Thilini],
  sk: [TextToSpeechVoice.Lukas, TextToSpeechVoice.Viktoria],
  sl: [TextToSpeechVoice.Rok, TextToSpeechVoice.Petra],
  so: [TextToSpeechVoice.Muuse, TextToSpeechVoice.Ubax],
  sq: [TextToSpeechVoice.Ilir, TextToSpeechVoice.Anila],
  sr: [TextToSpeechVoice.Nicholas, TextToSpeechVoice.Sophie],
  su: [TextToSpeechVoice.Jajang, TextToSpeechVoice.Tuti],
  sv: [TextToSpeechVoice.Mattias, TextToSpeechVoice.Sofie],
  sw: [TextToSpeechVoice.Daudi, TextToSpeechVoice.Rehema],
  ta: [TextToSpeechVoice.Valluvar, TextToSpeechVoice.Pallavi],
  te: [TextToSpeechVoice.Mohan, TextToSpeechVoice.Shruti],
  th: [TextToSpeechVoice.Niwat, TextToSpeechVoice.Premwadee],
  tr: [TextToSpeechVoice.Ahmet, TextToSpeechVoice.Emel],
  uk: [TextToSpeechVoice.Ostap, TextToSpeechVoice.Polina],
  ur: [TextToSpeechVoice.Salman, TextToSpeechVoice.Gul],
  uz: [TextToSpeechVoice.Sardor, TextToSpeechVoice.Madina],
  vi: [TextToSpeechVoice.NamMinh, TextToSpeechVoice.HoaiMy],
  wuu: [TextToSpeechVoice.Yunzhe, TextToSpeechVoice.Xiaotong],
  yue: [TextToSpeechVoice.YunSong, TextToSpeechVoice.XiaoMin],
  zh: [TextToSpeechVoice.Yunyang, TextToSpeechVoice.Xiaoxiao],
  zu: [TextToSpeechVoice.Themba, TextToSpeechVoice.Thando],
};

export const betaVoices = [
  TextToSpeechVoice.UnrealZoe,
  TextToSpeechVoice.UnrealAmy,
  TextToSpeechVoice.UnrealWill,
  TextToSpeechVoice.UnrealDanV7,
  TextToSpeechVoice.UnrealLivV7,
];

export const KokoroVoices = [
  TextToSpeechVoice.KokoroDefault,
  TextToSpeechVoice.KokoroBella,
  TextToSpeechVoice.KokoroSarah,
  TextToSpeechVoice.KokoroAdam,
  TextToSpeechVoice.KokoroMichael,
  TextToSpeechVoice.KokoroEmma,
  TextToSpeechVoice.KokoroIsabella,
  TextToSpeechVoice.KokoroGeorge,
  TextToSpeechVoice.KokoroLewis,
  TextToSpeechVoice.KokoroNicole,
  TextToSpeechVoice.KokoroSky,
];

export enum TrackPlayerState {
  // no track currently playing, paused or loading
  Loading = 'loading',
  Off = 'off',
  Paused = 'paused',
  Playing = 'playing',
}

export type PlaybackRate = 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2 | 2.25 | 2.5 | 2.75 | 3;
export const PlaybackRates: PlaybackRate[] = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3];

export type TextToSpeechInfo = {
  autoScrolling: boolean;
  currentlyPlayingListQuery: MangoQuery<AnyDocument> | null;
  playingDocId: string; // ID of document currently being listened to with TTS
  playingChunkId: string;
  startingPositionInChunk: number;
  trackPlayerInfo?: {
    isPlaying: boolean;
    position: number; // seconds
  } | null;
};

export type TextToSpeechSettings = {
  chosenVoiceForLanguage: { [lang in TTSLanguage]?: TextToSpeechVoice };
  defaultVoiceSettings: {
    defaultGender: TextToSpeechGender;
    defaultLanguage: TTSLanguage;
  };
  playbackRate: PlaybackRate;
  volume?: number;
};

export type TextToSpeechTrack = {
  artist: NonNullable<FirstClassDocument['author']>;
  artwork: NonNullable<FirstClassDocument['image_url']>;
  title: NonNullable<FirstClassDocument['title']>;
  url: string;
  chunkId: string;
  docId: string;
};
