import type { Category, EpubToc } from './index';

// Modeled after ePub asset types. New types may be added in the future.
export enum DocumentChunkType {
  Unknown = 'UNKNOWN',
  Image = 'IMAGE',
  Style = 'STYLE',
  Script = 'SCRIPT',
  Navigation = 'NAVIGATION',
  Vector = 'VECTOR',
  Font = 'FONT',
  Video = 'VIDEO',
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Cover = 'COVER',
  Smil = 'SMIL',
}

export const DOCUMENT_CHUNK_TYPES_TEXT = new Set([
  DocumentChunkType.Document,
  DocumentChunkType.Style,
  DocumentChunkType.Navigation,
  DocumentChunkType.Script,
  DocumentChunkType.Smil,
]);

// Chunk stored in the chunkedDocumentStore, downloaded from server via document_content_v2 endpoint.
export type DocumentChunk = {
  // ParsedDocumentChunk ID, unique in our database
  id: string;
  // ID unique within the document
  internal_id: string;
  // base64 encoded, potentially encrypted
  data: string;
  // necessary to make internal links across segments work. these links combine filename and target anchor ID.
  filename: string;
  password: string | null;
  is_encrypted: boolean;
  html_child_node_count: number | null;
  word_count: number | null;
  type: DocumentChunkType;
};

export type DocumentChunkMap = { [internalId: string]: DocumentChunk; };
export type DocumentChunkMapByFilename = { [filename: string]: DocumentChunk; };

export type DocumentChunkManifestEntry = {
  // unique ID of ParsedDocumentChunk in the server-side DB
  id: string;
  // original absolute filepath of EPUB asset
  filename: string;
  type: DocumentChunkType;
};

// map of chunk internal IDs to 'manifest entry'. modeled after EPUB spec manifest.
// used to load chunk data from the server.
export type DocumentChunkManifest = { [internalId: string]: DocumentChunkManifestEntry; };

// output of useDocumentChunkContentMap(), a function responsible for unpacking and decrypting chunk content from Store.
export type DocumentChunkContentMap = {
  [chunkId: string]: DocumentChunkTextContent | undefined;
};

// chunk content containing text data
export type DocumentChunkTextContent = {
  type:
    | DocumentChunkType.Document
    | DocumentChunkType.Style
    | DocumentChunkType.Smil
    | DocumentChunkType.Script
    | DocumentChunkType.Navigation;
  id: string;
  text: string;
  data: undefined;
};

// chunk content containing binary data
export type DocumentChunkDataContent = {
  type:
    | DocumentChunkType.Unknown
    | DocumentChunkType.Image
    | DocumentChunkType.Vector
    | DocumentChunkType.Font
    | DocumentChunkType.Video
    | DocumentChunkType.Audio
    | DocumentChunkType.Cover;
  id: string;
  text: undefined;
  data: ArrayBuffer;
};

export type DocumentChunkContent = DocumentChunkTextContent | DocumentChunkDataContent;

export type WebviewDocumentChunk = {
  // ID which is unique within the document
  id: string;
  // index in the document spine
  index: number;
  // original filename in the EPUB
  filename: string;
  // count of top-level nodes within the <body> tag.
  // NOTE: includes text nodes (i.e. not just elements).
  html_child_node_count: number;
  // HTML content of chunk, if loaded from content store.
  // includes images inlined as base64 data URLs.
  content: string | null;
  // number of words in the chunk
  word_count: number | null;
};

export type WebviewStyleChunk = {
  id: string;
  // original filename in the EPUB
  filename: string;
  // the text content of the style tag
  content: string | null;
};

export type WebviewChunks = {
  document: WebviewDocumentChunk[];
  style: WebviewStyleChunk[];
  toc: EpubToc[] | undefined;
};

export type ChunkSanitizationOptions = {
  isOriginalEmailView: boolean;
  showEnhancedYouTubeTranscript: boolean | undefined;
  category: Category;
};
export const UNCHUNKED_DOCUMENT_CHUNK_ID = 'single-chunk-full-content';

