import { isChunkContainer, isContentRootWithMultipleChunks, isHTMLElement } from '../../typeValidators';
import exceptionHandler from '../../utils/exceptionHandler.platform';
// eslint-disable-next-line import/no-cycle
import { forceContentLoadForChunk } from '../contentFramePortalGateInternalMethods';
import type { ChunkContainerElement } from '../types/chunkedDocuments';
import { findChunkContainerForNode } from './findChunkContainerForNode';
import { resolveRelativePath } from './getChunkContentFromFilename';

/**
 * Returns true if the link is an external link.
 *
 * This function checks if the link starts with a protocol (e.g. `http:`, `https:`, `ftp:`, `mailto:`, `tel:`, etc.).
 * It does not match invalid protocols like `123http:`, `@http:`, etc.
 */
export function isExternalLink(link: string): boolean {
  return /^[a-zA-Z][a-zA-Z0-9+.-]*:/.test(link);
}

export function findScrollTargetInChunkContainer(container: ChunkContainerElement): HTMLElement {
  // element we want to scroll to is itself a chunk container, which isn't a valid scroll target.
  // therefore we grab the first element inside the container as a scroll target.
  if (!isHTMLElement(container.firstElementChild)) {
    exceptionHandler.captureException('ScrollingManager: chunk container has no child element to scroll to', {
      extra: {
        dataset: container.dataset,
      },
    });
    return container;
  }
  return container.firstElementChild;
}

export async function forceChunkContentLoadForContainer(container: ChunkContainerElement): Promise<void> {
  const surroundingContainers = [
    container.previousElementSibling,
    container,
    container.nextElementSibling,
  ].filter(isChunkContainer);
  // forceContentLoadForChunk is a no-op if chunk contents are already loaded, in which case this will be instant.
  await Promise.all(surroundingContainers.map(
    (container) => forceContentLoadForChunk(container.dataset.chunkId),
  ));
}

type LoadChunkForContainer = (container: ChunkContainerElement) => Promise<void>;

export async function getHeadingElement(elementReference: string, isEpub: boolean, contentRoot: HTMLElement, loadChunkForContainer: LoadChunkForContainer) {
  const isChunked = isContentRootWithMultipleChunks(contentRoot);
  const attribute = getAttributeForHeadingId(isEpub);

  return isChunked
    ? getElementForChunkedDoc(elementReference, contentRoot, loadChunkForContainer)
    : getElementForUnchunkedDoc(elementReference, contentRoot, attribute);
}

/**
 * Returns the element to scroll to based on the elementReference.
 *
 * This function will also load the chunk containing the scroll target with the given load function.
 *
 * @param elementReference - The element reference to scroll to.
 * @param contentRoot - The content root element.
 * @param loadChunkForContainer - The function to load the chunk containing the scroll target.
 * @param sourceLinkElement - The link element that triggered the scroll.
 */
export async function getScrollTargetForInternalLink(elementReference: string, contentRoot: HTMLElement, loadChunkForContainer: LoadChunkForContainer, sourceLinkElement?: HTMLElement) {
  const isChunked = isContentRootWithMultipleChunks(contentRoot);

  return isChunked
    ? getElementForChunkedDoc(elementReference, contentRoot, loadChunkForContainer, sourceLinkElement)
    : getElementForUnchunkedDoc(elementReference, contentRoot, 'id');
}

async function getElementForUnchunkedDoc(elementReference: string, contentRoot: HTMLElement, attribute: string) {
  const strippedId = elementReference.replace('#', '');
  const elementToScrollTo = contentRoot.querySelector<HTMLElement>(`[${attribute}="${strippedId}"]`);
  if (!elementToScrollTo) {
    exceptionHandler.captureException('scrollToHeadingId: No element to scroll to found', { extra: { elementReference, attribute } });
    return;
  }
  return elementToScrollTo;
}

async function getElementForChunkedDoc(elementReference: string, documentTextContent: HTMLElement, loadChunkForContainer: (container: ChunkContainerElement) => Promise<void>, sourceElement?: HTMLElement) {
  // The headingId for chunked epubs includes first the path to the chunk container
  // and then the id of the element within the chunk, e.g. "Text/chapter-5.html#p3"
  const [filename, headingId] = elementReference.split('#');

  // If we only have an ID (no filename), search in the current chunk
  if (!filename && sourceElement) {
    const sourceContainer = findChunkContainerForNode(sourceElement, documentTextContent);
    if (!sourceContainer) {
      exceptionHandler.captureException('Could not find chunk container from source element', { extra: { sourceElement, elementReference } });
      return;
    }
    return findElementByIdInContainer(headingId, sourceContainer, elementReference);
  }

  const resolvedChunkFilename = resolveChunkFilename(filename, sourceElement, documentTextContent, elementReference);
  if (!resolvedChunkFilename) {
    return;
  }

  const container = documentTextContent.querySelector<ChunkContainerElement>(`[data-chunk-filename="${resolvedChunkFilename}"]`);
  if (!container) {
    exceptionHandler.captureException('Could not find chunk container from filename', { extra: { resolvedChunkFilename, headingId, elementReference } });
    return;
  }

  await loadChunkForContainer(container);
  return findScrollTargetInContainer(container, headingId, elementReference);
}

function findElementByIdInContainer(id: string, container: ChunkContainerElement, elementReference: string): HTMLElement | null {
  const sanitizedId = CSS.escape(id);
  const scrollTarget = container.querySelector<HTMLElement>(`#${sanitizedId}`);
  if (!scrollTarget) {
    exceptionHandler.captureException('Could not find element with ID in container', { extra: { id, elementReference } });
    return null;
  }
  return scrollTarget;
}

function resolveChunkFilename(filename: string | undefined, sourceElement: HTMLElement | undefined, documentTextContent: HTMLElement, elementReference: string): string | undefined {
  if (!filename || !sourceElement) {
    return filename;
  }

  const sourceContainer = findChunkContainerForNode(sourceElement, documentTextContent);
  if (!sourceContainer) {
    exceptionHandler.captureException('Could not find chunk container from source element', { extra: { sourceElement, elementReference } });
    return;
  }
  return resolveRelativePath(sourceContainer.dataset.chunkFilename, filename);
}

function findScrollTargetInContainer(container: ChunkContainerElement, headingId: string | undefined, elementReference: string): HTMLElement | null {
  let scrollTarget: HTMLElement | Range | null;

  if (headingId) {
    scrollTarget = findElementByIdInContainer(headingId, container, elementReference);
    if (!scrollTarget) {
      // let's at least scroll to the top of the container
      scrollTarget = container;
    }
  } else {
    scrollTarget = findScrollTargetInChunkContainer(container);
  }

  if (!scrollTarget) {
    exceptionHandler.captureException('Could not find a scroll target within the chunk', { extra: { elementReference, chunkId: container.dataset.chunkId } });
    return null;
  }

  return scrollTarget;
}

function getAttributeForHeadingId(isEpub: boolean) {
  return isEpub ? 'data-rw-epub-toc' : 'id';
}

