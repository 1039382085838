// eslint-disable-next-line import/no-cycle
import {
  startChunkContainerIntersectionObservers,
  stopChunkContainerIntersectionObservers,
} from '../../foreground/contentFramePortalGateInternalMethods';
import { findScrollTargetInChunkContainer } from '../../foreground/utils/chunkedScrolling';
import {
  populateValidElementsToScrollTo,
} from '../../foreground/utils/findAllFocusableElements';
import { findCenteredElementInViewport } from '../../foreground/utils/findCenteredElementInViewport';
import { makeWebviewLogger } from '../../foreground/utils/makeWebviewLogger';
import { isHTMLElement } from '../../typeValidators';
import delay from '../../utils/delay';
import { ScrollingManagerError } from './errors';
import { animateEndOfReadingButton } from './initEndOfReading';
// eslint-disable-next-line import/no-cycle
import { ScrollingManager } from './ScrollingManager';
import { CLICKABLE_TAGS } from './types';

const logger = makeWebviewLogger(__filename, { shouldLog: false });
export class VerticalScrollingManager extends ScrollingManager {
  updateCenteredElementThrottleCounter = 0;
  currentlyScrollingBecauseOfTouch = false;
  currentHeight = 0;

  // This class handles specific vertical scrolling functions
  init(): void {
    if (this.initialized) {
      return;
    }
    super.init();
    this.pageHeight = this.window.innerHeight;
    this.addEventListener(this.window, 'scroll', this.onScroll.bind(this));
    this.addEventListener(this.window, 'touchmove', this.onTouchMove.bind(this));
    if (!this.documentRoot) {
      throw new ScrollingManagerError('init: No document root found');
    }
    this.addEventListener(this.documentRoot, 'click', this.onRootClicked.bind(this));

    this.updateCurrentCenteredElement();
    this.initialized = true;
    this.currentHeight = this.documentTextContent?.getBoundingClientRect().height ?? 0;
    this.enableScrollEvents();
  }

  onRootClicked(e: Event) {
    const target = e.target as Element;
    if (target && isHTMLElement(target) && CLICKABLE_TAGS.has(target.nodeName)) {
      return;
    }
    this.window.portalGateToForeground.emit('root-clicked');
  }

  destroy() {
    super.destroy();
    this.removeAllEventListeners();
  }

  async onResize() {
    if (!this.initialized) {
      return;
    }
    if (this.isOnResizeDisabled) {
      return;
    }
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (this.documentTextContentHeight === newHeight || !newHeight) {
      return;
    }
    this.documentTextContentHeight = newHeight;
    logger.debug(`OnResize: fired , ${this.getScrollingElementTop()}`);
    if (this.getScrollingElementTop() <= 0) {
      // If we are at the top, we don't need to do anything
      return;
    }
    const scrollDelta = this.currentCenteredElementInfo?.scrollDelta;
    if (scrollDelta === undefined || !this.currentCenteredElementInfo.element) {
      logger.debug('OnResize failed due to no center element');
      return;
    }
    if (this.currentlyScrollingBecauseOfTouch) {
      logger.debug(
        `resize blocked, currentlyScrollingBecauseOfTouch: ${this.currentlyScrollingBecauseOfTouch};`,
      );
      return;
    }

    const element = this.currentCenteredElementInfo.element;
    const elementTop = this.getAbsoluteScrollTopOfRange(element);
    const y = elementTop - scrollDelta;
    this.setScrollingElementTop(Math.max(0, y));
  }

  updateCurrentCenteredElement() {
    logger.debug('UpdateCurrentCenteredElement fired');
    if (!this.documentTextContent) {
      this.initializeHTMLComponents();
      if (!this.documentTextContent) {
        throw new ScrollingManagerError('UpdateCurrentCenteredElement Document Text Container not found');
      }
    }
    if (!this.focusableElements.length) {
      populateValidElementsToScrollTo(this.documentTextContent, this.focusableElements);
    }

    const currentChunkContainer = this.getChunkContainerByIndex(this.currentChunkIndex);
    let centeredElement = findCenteredElementInViewport(
      this.focusableElements,
      this.window,
    ) as HTMLElement;

    if (!centeredElement) {
      centeredElement = findScrollTargetInChunkContainer(currentChunkContainer);
    }

    logger.debug('updateCurrentCenteredElement ', {
      centeredElement,
      top: centeredElement?.getBoundingClientRect().top,
    });

    // Uncomment for debug purposes
    // const prevElementDebug = this.document.querySelector('.centeredElementDebug');
    // prevElementDebug?.classList.remove('centeredElementDebug');
    // centeredElement?.classList.add('centeredElementDebug');
    // console.log("remember to comment me back out")

    const top = centeredElement?.getBoundingClientRect().top;
    this.currentCenteredElementInfo = {
      element: centeredElement,
      scrollDelta: top,
      absoluteScrollTopOffset: centeredElement?.getBoundingClientRect().top,
    };

    this.updateCurrentChunkIndex(centeredElement);
  }

  isDocumentScrolledToBeginning(): boolean {
    const scrollableRoot = this.getScrollingElement();
    return scrollableRoot.scrollTop < 100;
  }

  scrollToTop() {
    if (this.currentlyScrollingBecauseOfTouch) {
      return;
    }
    super.scrollToTop();
  }

  scrollToStartOfDocument() {
    this.scrollToTop();
  }

  scrollToPosition(scrollTop: number) {
    const scrollingElement = this.getScrollingElement();
    scrollingElement.scrollTo({ top: scrollTop });
  }

  scrollViewportToCurrentTTSLocation(rect: DOMRect) {
    const offsetFromTop = 350;
    const scrollByAmount = rect.top - offsetFromTop;
    const scrollableRoot = this.getScrollingElement();
    const newScrollTop =
      Math.abs(scrollByAmount) > 100
        ? scrollableRoot.scrollTop + scrollByAmount
        : scrollableRoot.scrollTop;
    if (Math.abs(scrollByAmount) < 100) {
      return;
    }
    if (this.ttsAutoScrollingEnabled) {
      scrollableRoot.scrollTo({ top: newScrollTop, behavior: 'smooth' });
    }
  }

  async returnToReadingPosition() {
    if (this.readingPosition) {
      await this.scrollToReadingPosition(this.readingPosition);
    } else {
      this.scrollToTop();
    }
    await delay(4);
    const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
    const scrollDepth = this.getScrollDepth();
    const readingProgressDepth = await this.getReadingProgress();
    this.window.portalGateToForeground.emit('return_to_reading_position', {
      chunkScrollDepth: scrollDepth,
      chunkReadingProgressDepth: readingProgressDepth,
      serializedPosition: serializedPositionInfo?.serializedPosition,
      serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
      scrollDelta: 0,
      chunkIndex: this.currentChunkIndex,
    });
  }

  getScrollingElementMaxScroll() {
    const { scrollHeight } = this.getScrollingElement();
    return scrollHeight;
  }

  async onScrollStart() {
    this.updateCenteredElementThrottleCounter = 0;
    if (!this.scrollingEventsDisabled) {
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      const scrollDepth = this.getScrollDepth();
      const readingProgressDepth = await this.getReadingProgress();
      return this.window.portalGateToForeground.emit('scroll_start', {
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
        scrollDelta: 0,
        chunkScrollDepth: scrollDepth,
        chunkReadingProgressDepth: readingProgressDepth,
        chunkIndex: this.currentChunkIndex,
      });
    }
  }

  async onScroll() {
    if (!this.headerImageContainer) {
      throw new ScrollingManagerError('Header image element not found');
    }
    const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
    this.currentScrollValue = scrollTop;

    const scrollDepth = this.getScrollDepth();
    const readingProgressDepth = await this.getReadingProgress();
    const scrollDelta = this.currentScrollValue - this.previousScrollValue;

    if (this.updateCenteredElementThrottleCounter === 0) {
      this.updateCurrentCenteredElement();
    }
    if (this.updateCenteredElementThrottleCounter > 2) {
      this.updateCenteredElementThrottleCounter = 0;
    }
    this.updateCenteredElementThrottleCounter += 1;


    const newIsScrollingDown = this.previousScrollValue < this.currentScrollValue;
    if (newIsScrollingDown !== this.isScrollingDown || !this.scrollTimer) {
      await this.onScrollStart();
    }

    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }

    this.isScrollingDown = newIsScrollingDown;
    this.previousScrollValue = this.currentScrollValue;

    if (!this.scrollingEventsDisabled) {
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      await this.window.portalGateToForeground.emit('scroll', {
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
        scrollDelta,
        chunkScrollDepth: scrollDepth,
        chunkReadingProgressDepth: readingProgressDepth,
        chunkIndex: this.currentChunkIndex,
      });
    }
    if (scrollTop < -5 || this.headerImageContainer.style.transform !== 'scale(1)') {
      const val = Math.min(3, 1 + -scrollTop * 0.005);
      this.headerImageContainer.style.transform = `scale(${Math.max(1, val)})`;
    }
    const scrollFromBottom = scrollHeight - scrollTop - clientHeight;

    // This check prevents animations before we have fully loaded the DOM
    if (!this.scrollingEventsDisabled) {
      animateEndOfReadingButton(scrollFromBottom);
    }

    this.scrollTimer = setTimeout(this.onScrollEnd.bind(this), 100);
    for (const func of this.scrollListeners) {
      func({
        currentCenterElementInfo: this.currentCenteredElementInfo,
        currentChunkElement: this.getChunkContainerByIndex(this.currentChunkIndex),
      });
    }
  }

  async onScrollEnd() {
    logger.debug('OnScrollEnd fired');
    this.currentlyScrollingBecauseOfTouch = false;
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }
    this.updateCenteredElementThrottleCounter = 0;
    this.updateCurrentCenteredElement();
    if (!this.scrollingEventsDisabled) {
      const scrollDepth = this.getScrollDepth();
      const readingProgressDepth = await this.getReadingProgress();
      const serializedPositionInfo = this.computeSerializedPositionFromCenteredElement();
      const scrollDelta = this.currentScrollValue - this.previousScrollValue;
      await this.window.portalGateToForeground.emit('scroll_end', {
        chunkScrollDepth: scrollDepth,
        chunkReadingProgressDepth: readingProgressDepth,
        serializedPosition: serializedPositionInfo?.serializedPosition,
        serializedElementVerticalOffset: serializedPositionInfo?.serializedPositionElementOffset,
        scrollDelta,
        chunkIndex: this.currentChunkIndex,
      });
    }
    this.scrollTimer = undefined;
  }

  onTouchMove() {
    if (this.ttsAutoScrollingEnabled) {
      this.window.portalGateToForeground.emit('set_tts_auto_scrolling_enabled', false);
    }
    this.currentlyScrollingBecauseOfTouch = true;
    if (this.touchMoveThrottle === 0) {
      this.window.portalGateToForeground.emit('touch_move');
    }
    this.touchMoveThrottle += 1;
    if (this.touchMoveThrottle > 10) {
      this.touchMoveThrottle = 0;
    }
  }

  updateCurrentChunkIndex(centeredElement: HTMLElement) {
    const chunkContainer = this.findChunkContainerForElement(centeredElement);

    if (chunkContainer) {
      const newIndex = parseInt(chunkContainer.dataset.chunkIndex, 10);
      this.setNewChunkIndex(newIndex);
    }
  }

  async setNewChunkIndex(newIndex: number) {
    if (newIndex === this.currentChunkIndex) {
      return;
    }
    this.currentChunkIndex = Math.min(Math.max(0, newIndex), this.numberOfChunks - 1);
  }

  handleChunksLoading() {}

  enableAllPaginationElements() {}

  disableAllPaginationElements() {}

  async _scrollToRangeOrElement(
    element: HTMLElement | Range,
    offset = 150, // 150 because we pretty much NEVER want the element to be right at the very top of the screen...
    behavior: 'smooth' | 'auto' | 'instant' = 'instant',
  ) {
    let elementToCenter: HTMLElement | undefined;
    if (element instanceof Range) {
      elementToCenter = this.findChunkContainerForElement(this.getFirstNodeInRange(element));
    } else {
      elementToCenter = element;
    }
    if (this.isChunked && elementToCenter) {
      await stopChunkContainerIntersectionObservers();
      this._temporarilyForceCenteredElement(elementToCenter, offset);
    }
    this.setSpinnerEnabled(false);
    const y = this.getAbsoluteScrollTopOfRange(element) - offset;

    this.scrollingElementScrollTo({ top: y, behavior });
    if (this.isChunked) {
      await startChunkContainerIntersectionObservers();
    }
  }

  _isScrolledToEndOfChunk(): boolean {
    const { scrollTop, scrollHeight, clientHeight } = this.getScrollingElement();
    // Use a small threshold (1px) to account for potential rounding errors
    return Math.abs(scrollTop + clientHeight - scrollHeight) <= 1;
  }
}
